import React from "react";
import Button from "react-bootstrap/Button";
import "./Banner.scss";

const Banner = ({ companyData }) => {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="banner-info">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-5 mt-3">
              <div className="header-banner aos" data-aos="fade-up">
                <h1 className="mb-4">
                  <span className="header-highlight">
                    {companyData.userText.bannerTitle}
                  </span>
                </h1>
                <h4 className="fw-normal">
                  {companyData.userText.bannerSubtitle}
                </h4>
              </div>
              <Button className="btn-primary mt-4">
                {companyData.userText.bannerButton}
              </Button>
            </div>
            <div className="col-lg-6">
              <img
                alt="imagen principal"
                className="hero-img"
                src={companyData.bannerImag}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
