import React from 'react';
import { useTranslation } from 'react-i18next';
import './Sidebar.scss';

const Sidebar = ({ currentStep, setCurrentStep }) => {
  const { t } = useTranslation();

  const steps = [
    { step: 1, label: t('register'), description: t('complete_registration_step') },
    { step: 2, label: t('profile'), description: t('enter_data_step') },
    { step: 3, label: t('schedule'), description: t('setup_schedule_step') },
    { step: 4, label: t('services'), description: t('add_services_step') },
    { step: 5, label: t('payment_methods'), description: t('setup_payment_methods_step') },
    { step: 6, label: t('password'), description: t('create_password_step') },
  ];

  return (
    <div className="sidebar">
      <ul className="list-group">
        {steps.map(({ step, label, description }) => (
          <li
            key={step}
            className={`list-group-item ${currentStep === step ? 'active' : ''} ${currentStep > step ? 'completed' : ''}`}
            onClick={() => (currentStep > step ? setCurrentStep(step) : null)}
            style={{ cursor: currentStep > step ? 'pointer' : 'default' }}
          >
            <div className={`step-indicator ${currentStep >= step ? 'completed' : ''}`}>{step}</div>
            <div className="step-content">
              <div className="step-label">{label}</div>
              <div className="step-description">{description}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
