import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Register from '../Onboarding/Register/Register';
import Profile from '../Onboarding/Profile/Profile';
import Sidebar from '../Onboarding/Sidebar/Sidebar';
import Schedule from '../Onboarding/Schedule/Schedule';
import Services from '../Onboarding/Services/Services';
import PaymentMethods from '../Onboarding/PaymentMethods/PaymentMethods';
import Password from '../Onboarding/Password/Password';
import Success from '../Onboarding/Success/Success';
import ProgressBar from '../../Utils/ProgressBar/ProgressBar';
import './index.scss';

const Onboarding = () => {
  const [formData, setFormData] = useState({});
  const totalSteps = 7;
  const navigate = useNavigate();

  const handleNext = (data, nextStep) => {
    setFormData({ ...formData, ...data });
    navigate(`/onboarding/${nextStep}`);
  };

  return (
    <div className="onboarding">
      <ProgressBar step={parseInt(window.location.pathname.split('/').pop()) || 1} totalSteps={totalSteps} />
      <Sidebar currentStep={parseInt(window.location.pathname.split('/').pop()) || 1} />
      <div className="onboarding-content">
        <Routes>
          <Route path="/" element={<Register onNext={(data) => handleNext(data, 2)} />} />
          <Route path="/2" element={<Profile data={formData} onNext={(data) => handleNext(data, 3)} />} />
          <Route path="/3" element={<Schedule data={formData} onNext={(data) => handleNext(data, 4)} />} />
          <Route path="/4" element={<Services data={formData} onNext={(data) => handleNext(data, 5)} />} />
          <Route path="/5" element={<PaymentMethods data={formData} onNext={(data) => handleNext(data, 6)} />} />
          <Route path="/6" element={<Password data={formData} onNext={(data) => handleNext(data, 7)} />} />
          <Route path="/7" element={<Success />} />
        </Routes>
      </div>
    </div>
  );
};

export default Onboarding;
