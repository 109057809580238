import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Password.scss';

const Password = ({ onNext }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError(t('password_mismatch'));
      return;
    }
    onNext({ password });
  };

  return (
    <div className="password-setup container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <h2>{t('password')}</h2>
          <p>{t('password_description')}</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="password">{t('create_password')}</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">{t('confirm_password')}</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                className="form-control"
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit" className="btn btn-primary w-100 mt-3">{t('continue')}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Password;
