import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  Calendar4Week,
  Envelope,
  List,
  Telephone,
  XLg,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import "./IndexHeader.scss";

function Header({ companyData }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar className="navbar-user" expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={companyData.logo} alt="Logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-lg"
            onClick={handleShow}
          >
            <List size={28} />
          </Navbar.Toggle>
          <Navbar.Offcanvas
            show={show}
            onHide={handleClose}
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
          >
            <Offcanvas.Header>
              <img src={companyData.logo} alt="Logo" className="logo" />
              <Button className="custom-close-button" onClick={handleClose}>
                <XLg size={24} />
              </Button>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="header-info">
                <div className="info-container">
                  <Telephone />
                  <a href={"tel:" + companyData.phone}>{companyData.phone}</a>
                </div>
                <div className="info-container">
                  <Envelope />
                  <a href={"mailto:" + companyData.email}>
                    {companyData.email}
                  </a>
                </div>
                <Button className="btn-header btn-secondary">
                  <Calendar4Week />
                  {/* //{t("Schedule an appointment!")} */}
                  {companyData.userText.headerButton}
                </Button>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
