// src/store/professionalsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { professionalsAPI } from "../services/api";

// Asynchronous thunk actions
export const fetchProfessionals = createAsyncThunk(
  "professionals/fetchProfessionals",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await professionalsAPI.getProfessionalsByCompanyId(
        companyId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const createProfessional = createAsyncThunk(
  "professionals/createProfessional",
  async (professionalData, { rejectWithValue }) => {
    try {
      const response = await professionalsAPI.createProfessional(
        professionalData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);
export const getProfessionalsByCompanyId = createAsyncThunk(
  "professionals/getProfessionalsByCompanyId",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await professionalsAPI.getProfessionalsByCompanyId(
        companyId
      );
      console.log("response, getProfessionalsByCompanyId", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const updateProfessional = createAsyncThunk(
  "professionals/updateProfessional",
  async (professionalData, { rejectWithValue }) => {
    try {
      const response = await professionalsAPI.updateProfessional(
        professionalData._id,
        professionalData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);
export const addServiceToProfessional = createAsyncThunk(
  "professionals/addServiceToProfessional",
  async ({ id, companyId, serviceId }, { rejectWithValue }) => {
    try {
      const response = await professionalsAPI.addServiceToProfessional(id, {
        companyId: companyId,
        serviceIds: serviceId,
      });
      console.log("response, addServiceToProfessional", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Unknown error occurred"
      );
    }
  }
);

const professionalsSlice = createSlice({
  name: "professionals",
  initialState: {
    professionals: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfessionals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfessionals.fulfilled, (state, action) => {
        state.loading = false;
        state.professionals = action.payload;
      })
      .addCase(fetchProfessionals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createProfessional.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProfessional.fulfilled, (state, action) => {
        state.loading = false;
        state.professionals.push(action.payload);
      })
      .addCase(createProfessional.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = professionalsSlice.actions;

export default professionalsSlice.reducer;
