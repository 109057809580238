import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAvailabilityByCompanyId } from "../../../../../store/availabilitySlice";
import "./AddAppointmentModal.scss";

const AddAppointmentModal = ({
  isModalOpen,
  closeModal,
  newAppointment,
  handleInputChange,
  handleSubmit,
  errors,
  services,
}) => {
  const { t } = useTranslation();
  const [selectedService, setSelectedService] = useState(null);
  const [professionalAvailability, setProfessionalAvailability] = useState([]);
  const [professionalsByServices, setProfessionalsByServices] = useState(null);
  const [availabilityByCompany, setAvailabilityByCompany] = useState(null);
  const { professionals } = useSelector((state) => state.professionals);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAvailability = async () => {
      const resp = await getAvailability();
      setAvailabilityByCompany(resp);
    };

    fetchAvailability();
  }, []);

  const getServices = async (serviceId) => {
    setProfessionalsByServices(null);
    const serviceSelected = services.find(
      (service) => service._id === serviceId
    );
    console.log("SELECSERVICE", serviceSelected);
    setSelectedService(serviceSelected);
    if (
      !serviceSelected.professionals ||
      serviceSelected.professionals.length === 0
    ) {
      setProfessionalAvailability(serviceSelected.availability || []);
      const availabilityOfServices = availabilityByCompany.filter(
        (availability) => availability.serviceId === serviceId
      );
      setProfessionalAvailability(availabilityOfServices);
      return;
    }
    const allProfessionals = professionals.filter((professional) =>
      serviceSelected.professionals.includes(professional._id)
    );
    setProfessionalsByServices(allProfessionals);
  };
  const getAvailability = async () => {
    const { payload } = await dispatch(getAvailabilityByCompanyId(user._id));
    if (!payload) return;
    return payload;
  };
  const getAvailabiltyByProfessional = async (professionalId) => {
    setProfessionalAvailability([]);
    console.log("availabilityByCompany", availabilityByCompany);
    const filteredAvailability = availabilityByCompany.filter(
      (availability) => availability.professionalId === professionalId
    );
    console.log("filteredAvailability", filteredAvailability);
    setProfessionalAvailability(filteredAvailability);
  };

  const selectDateAndTime = (date, time) => {
    handleInputChange({
      target: { name: "date", value: new Date(`${date}T${time}:00`) },
    });
  };

  const renderAvailabilityButtons = () => {
    const availabilities =
      professionalAvailability.length > 0
        ? professionalAvailability.flatMap(
            (availability) => availability.availability
          )
        : selectedService?.availability || [];

    // console.log("availabilities", availabilities); // Verificar los datos aquí

    return availabilities.map((avail, index) => {
      console.log("avail", avail); // Verificar cada disponibilidad
      return (
        avail &&
        avail.date &&
        avail.timeSlots && (
          <div key={index} className="availability-day">
            <h5>{avail.date}</h5>
            <div className="availability-times">
              {avail.timeSlots.map((timeSlot, idx) => {
                const [startTime] = timeSlot.split("-");
                return (
                  <button
                    key={idx}
                    className="time-slot-button"
                    onClick={() => selectDateAndTime(avail.date, startTime)}
                  >
                    {startTime}
                  </button>
                );
              })}
            </div>
          </div>
        )
      );
    });
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {newAppointment.associate
            ? `${t("add_appointment_for")} ${newAppointment.associate}`
            : t("add_appointment")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="client">
            <Form.Label>{t("name")}</Form.Label>
            <Form.Control
              type="text"
              name="client"
              value={newAppointment.client}
              onChange={handleInputChange}
              isInvalid={!!errors.client}
            />
            <Form.Control.Feedback type="invalid">
              {errors.client}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>{t("email")}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={newAppointment.email}
              onChange={handleInputChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>{t("phone")}</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={newAppointment.phone}
              onChange={handleInputChange}
              isInvalid={!!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="service">
            <Form.Label>{t("service")}</Form.Label>
            <Form.Control
              as="select"
              name="service"
              value={newAppointment.serviceId}
              onChange={(e) => getServices(e.target.value)}
              isInvalid={!!errors.service}
            >
              <option value="">{t("select_service")}</option>
              {services.map((service) => (
                <option key={service._id} value={service._id}>
                  {service.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.service}
            </Form.Control.Feedback>
          </Form.Group>
          {selectedService &&
            selectedService.professionals &&
            selectedService.professionals.length > 0 &&
            professionalsByServices && (
              <Form.Group controlId="professional">
                <Form.Label>{t("associate")}</Form.Label>
                <Form.Control
                  as="select"
                  name="professional"
                  value={newAppointment.associateId}
                  onChange={(e) => getAvailabiltyByProfessional(e.target.value)}
                  isInvalid={!!errors.associate}
                >
                  <option value="">{t("select_associate")}</option>
                  {professionalsByServices.map((professional) => (
                    <option key={professional._id} value={professional._id}>
                      {professional.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.associate}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          {selectedService && (
            <Form.Group controlId="date">
              <Form.Label>{t("date")}</Form.Label>
              <div className="availability-container">
                {renderAvailabilityButtons()}
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <div className="mt-3">
            <Button variant="primary" type="submit">
              {t("save")}
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              {t("cancel")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAppointmentModal;
