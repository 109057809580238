import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HandThumbsUp, Chat, GeoAlt, Cash, InfoCircle } from 'react-bootstrap-icons';
import './AsociateWidget.scss';
import { useTranslation } from 'react-i18next';
import AddAppointmentModal from '../../Client/ClientDashboard/Appointments/AddAppointmentModal/AddAppointmentModal';
import { Button } from 'react-bootstrap';
import { getAllAssociates, getServicesByAssociateId } from '../../../Utils/dateAvailable/dataStorage';


const AsociateWidget = ({ item }) => {
  const { t } = useTranslation();
  const allAssociates = getAllAssociates();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [associates, setAssociates] = useState([]);
  const [newAppointment, setNewAppointment] = useState({
    client: '',
    email: '',
    phone: '',
    date: new Date(),
    service: '',
    associate: ''
  });
    const [errors, setErrors] = useState({});



  console.log('item', item);
  const formatPrice = (price) => {
  return new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
  }).format(price);
  };
  
  const openModal = (data) => {     
    setServices([data]);
    if (data.idAssociate) {
      allAssociates.map(resp => {
        if (resp.id === data.idAssociate) {
           setAssociates([resp])
        }
      })
    };
    console.log(data);
  setNewAppointment({
      client: '',
      email: '',
      phone: '',
      date: new Date(),
      serviceId: data.id,
      associateId: data.idAssociate
    });
    
   setIsModalOpen(true);
  }
   const closeModal = () => {
    setIsModalOpen(false);
    setNewAppointment({
      client: '',
      email: '',
      phone: '',
      date: new Date(),
      serviceId: '',
      associateId: ''
    });
    // setErrors({});
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment({
      ...newAppointment,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    setNewAppointment({
      ...newAppointment,
      date: date
    });
  };
  
    const validateFields = () => {
    const { client, email, phone, date, serviceId, associateId } = newAppointment;
    const newErrors = {};

    if (!client) newErrors.client = t('required_field');
    if (!email) newErrors.email = t('required_field');
    if (!phone) newErrors.phone = t('required_field');
    if (!date) newErrors.date = t('required_field');
    if (!serviceId) newErrors.serviceId = t('required_field');
    if (!associateId) newErrors.associateId = t('required_field');

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
    };
  
  const handleSubmit = (e) => {
     console.log('Vine', e);
    e.preventDefault();
    console.log('newAppointment', newAppointment);
    console.log('validateFields', validateFields());
    if (validateFields()) {
      // appointments.push(newAppointment);
      closeModal();
  }
  };

  
  return (
    <div className="card">
      <div className="card-body">
        <div className="doctor-widget">
          <div className="doc-info-left">
            <div className="doctor-img">
              {item && item.imageUrl && (
                <Link to="/doctor-profile">
                  <img src={item.imageUrl} className="img-fluid" alt="User Image" />
                </Link>
              )}
            </div>
            <div className="doc-info-cont">
              <h4 className="doc-name">
                {item && item.name && <label className='mb-2'><Link to="/doctor-profile">{item.name}</Link></label>}
              </h4>
              {item && item.description && <p className="doc-speciality mb-2">{item.description}</p>}
              {item && item.associateName && <p className="doc-speciality mb-2">{item.associateName}</p>}

              <h5 className="doc-department">
                {item && item.imageUrl && <img
                  src={item.imageUrl}
                  className="img-fluid"
                  alt="Speciality"
                />}
                {item && item.specialist && <>{item.specialist}</>}
              </h5>
             
              <div className="clinic-details">
                {item && item.place && (
                  <p className="doc-location">
                     <GeoAlt className='m-2'/> {item.place}
                  </p>
                )}
                {item.aditionalImg && 
               <ul className="clinic-gallery">
                      {item.aditionalImg.map((src, index) => (
                    <li className="me-1" key={index}>
                      <a className="fancystyle" data-fancybox="gallery">
                        <img src={src} key={index} alt="Feature" />
                      </a>
                    </li>
                  ))}
                </ul>
               } 
              </div>
              {item && (item.name1 || item.name2) && (
                <div className="clinic-services">
                  {item.name1 && <span>{item.name1}</span>}
                  {item.name2 && <span>{item.name2}</span>}
                </div>
              )}
            </div>
          </div>
          <div className="doc-info-right">
            <div className="clini-infos">
              {item && item.price && (
                <ul>
                  <li>
                    <HandThumbsUp className='m-2'/> {item.rate}%
                  </li>
                  <li>
                  <Chat className='m-2'/> {item.feed} Feedback
                  </li>
                  <li>
                  <GeoAlt className='m-2'/> {item.place}
                  </li>
                  <li>
                  <Cash className='m-2'/> {formatPrice(item.price)}
                  </li>
                </ul>
              )}
            </div>
            <div className="clinic-booking">
              {/* <Link className="apt-btn" to="/booking">
                {t('book_appointment')}
              </Link> */}
              <Button variant="primary" className='apt-btn' onClick={() => openModal(item)}>
                {t('book_appointment')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen &&
           <AddAppointmentModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        newAppointment={newAppointment}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        handleSubmit={handleSubmit}
        errors={errors}
        services={services}
        associates={associates}
      />
      
      
      }
   
    </div>
  );
};

export default AsociateWidget;
