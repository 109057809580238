import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminLogin from "./components/Admin/AdminLogin/login";
import Onboarding from "./components/Onboarding/index";
import AdminIndex from "./components/Admin/index";
import { useAuth } from "./auth/AuthContext";
import "./App.scss";
import "./i18n/i18n";
import ClientLogin from "./components/Client/ClientDashboard/Login/Login";
import Dashboard from "./components/Client/index";
import Index from "./components/User/Index/UserIndex";
import Search from "./components/User/Search/Search";
import { useSelector } from "react-redux";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <div className="App">
      <Routes>
        <Route path="/ventureLogin" element={<AdminLogin />} />
        <Route
          path="/admin/index"
          element={isAuthenticated ? <AdminIndex /> : <AdminLogin />}
        />
        <Route path="/onboarding/*" element={<Onboarding />} />
        <Route path="/login" element={<ClientLogin />} />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <ClientLogin />}
        />
        <Route path="/search/:id?" element={<Search />} />
        <Route path="/" element={<ClientLogin />} />
      </Routes>
    </div>
  );
}

export default App;
