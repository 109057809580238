import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getProfessionalsByCompanyId } from "../../../../store/professionalSlice";
import {
  getServiceById,
  getAvailabilityByProfessionalId,
  updateAvailability,
  createAvailability,
} from "../../../../store/availabilitySlice";
import "./Schedule.scss";

const initialHours = {
  L: [
    { start: "08:00", end: "12:30" },
    { start: "13:30", end: "18:30" },
  ],
  // Inicializa otros días de la semana si es necesario
};

const daysOfWeek = ["L", "M", "Mi", "J", "V", "S", "D"];

const Schedule = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { services } = useSelector((state) => state.services);
  const [professionals, setProfessionals] = useState(null);

  const [selectedService, setSelectedService] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [hours, setHours] = useState(initialHours);
  const [selectedDay, setSelectedDay] = useState("L");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBlock, setNewBlock] = useState({ start: "", end: "" });
  const [editingBlockIndex, setEditingBlockIndex] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [selectionMode, setSelectionMode] = useState("single");
  const [availabilityId, setAvailabilityId] = useState(null);

  const fetchAvailability = async () => {
    if (selectedService) {
      const service = services.find(
        (service) => service._id === selectedService
      );
      if (service && service.availability && service.availability.length > 0) {
        const { payload } = await dispatch(getServiceById(selectedService));
        if (payload) {
          const filteredAvailability = payload.filter((avail) =>
            service.availability.includes(avail._id)
          );
          const flattenedAvailability = filteredAvailability.flatMap(
            (avail) => avail.availability
          );
          if (flattenedAvailability.length > 0) {
            setAvailabilityId(filteredAvailability[0]._id);
            setAvailability(flattenedAvailability);
          }
        }
      } else {
        setAvailability([]);
      }
    } else if (selectedProfessional) {
      const professional = professionals.find(
        (profess) => profess._id === selectedProfessional
      );
      console.log("professional", professional);
      if (
        professional &&
        professional.availability &&
        professional.availability.length > 0
      ) {
        const { payload } = await dispatch(
          getAvailabilityByProfessionalId(selectedProfessional)
        );
        if (payload) {
          console.log("payload", payload);
          const filteredAvailability = payload.filter((avail) =>
            professional.availability.includes(avail._id)
          );
          console.log("filteredAvailability", filteredAvailability);
          const flattenedAvailability = filteredAvailability.flatMap(
            (avail) => avail.availability
          );
          console.log("flattenedAvailability", flattenedAvailability);
          if (flattenedAvailability.length > 0) {
            setAvailabilityId(filteredAvailability[0]._id);
            setAvailability(flattenedAvailability);
          }
        }
      }

      //llamar la api bb
      // Fetch availability for selected professional if needed
      setAvailability([]);
    }
  };

  useEffect(() => {
    fetchAvailability();
  }, [selectedService, selectedProfessional, dispatch, user._id]);

  useEffect(() => {
    const getProfessionalsByComId = async () => {
      const { payload } = await dispatch(getProfessionalsByCompanyId(user._id));
      setProfessionals(payload);
    };

    getProfessionalsByComId();
  }, [dispatch, user._id]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewBlock({ start: "", end: "" });
    setEditingBlockIndex(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBlock({ ...newBlock, [name]: value });
  };

  const saveBlock = () => {
    if (newBlock.start === "" || newBlock.end === "") {
      return;
    }

    if (editingBlockIndex !== null) {
      const updatedHours = { ...hours };
      updatedHours[selectedDay][editingBlockIndex] = newBlock;
      setHours(updatedHours);
    } else {
      setHours({
        ...hours,
        [selectedDay]: [...(hours[selectedDay] || []), newBlock],
      });
    }
    closeModal();
  };

  const editBlock = (index) => {
    setNewBlock(hours[selectedDay][index]);
    setEditingBlockIndex(index);
    openModal();
  };

  const deleteBlock = (index) => {
    const updatedHours = { ...hours };
    updatedHours[selectedDay].splice(index, 1);
    setHours(updatedHours);
  };

  const handleDateChange = (date) => {
    if (selectionMode === "single") {
      setSelectedDates([date]);
    } else if (selectionMode === "range") {
      const [start, end] = date;
      setSelectedDates([start, end]);
    } else if (selectionMode === "alternate") {
      const selectedDatesSet = new Set(selectedDates);
      if (selectedDatesSet.has(date)) {
        selectedDatesSet.delete(date);
      } else {
        selectedDatesSet.add(date);
      }
      setSelectedDates([...selectedDatesSet]);
    }
  };

  const addAvailability = () => {
    if (
      selectedDates.length === 0 ||
      (selectionMode === "range" && !selectedDates[1])
    ) {
      alert(t("select_date_range"));
      return;
    }

    const newAvailability = selectedDates.map((date) => ({
      date: date.toISOString().split("T")[0],
      timeSlots: hours[selectedDay].map(
        (block) => `${block.start}-${block.end}`
      ),
    }));

    setAvailability([...availability, ...newAvailability]);
    setSelectedDates([]);
  };

  const saveSchedule = async () => {
    //dayana
    try {
      if (availability.length > 0) {
        if (availabilityId) {
          await dispatch(
            updateAvailability({
              id: availabilityId,
              availability,
            })
          );
        } else {
          await dispatch(
            createAvailability({
              companyId: user._id,
              serviceId: selectedService,
              availability,
            })
          );
        }
      }

      fetchAvailability();
    } catch (error) {
      console.error("Error saving schedule:", error);
    }
  };

  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const isPastTime = (time) => {
    const now = new Date();
    const [hours, minutes] = time.split(":");
    const selectedTime = new Date();
    selectedTime.setHours(hours, minutes, 0, 0);
    return selectedTime < now;
  };

  const handleServiceSelect = (serviceId) => {
    setSelectedService(serviceId);
    setSelectedProfessional(null);
    setAvailability([]); // Clear availability
  };

  const handleProfessionalSelect = (professionalId) => {
    setSelectedProfessional(professionalId);
    setSelectedService(null);
    setAvailability([]); // Clear availability
  };

  return (
    <div className="schedule">
      <div className="column column-left">
        <div className="service-list">
          <h4>{t("services")}</h4>
          <ul>
            {services.map((service) => (
              <li
                key={service._id}
                onClick={() => handleServiceSelect(service._id)}
                className={selectedService === service._id ? "active" : ""}
              >
                {service.name}
              </li>
            ))}
          </ul>
        </div>
        {professionals && (
          <div className="professional-list">
            <h4>{t("professionals")}</h4>
            <ul>
              {professionals.map((professional) => (
                <li
                  key={professional._id}
                  onClick={() => handleProfessionalSelect(professional._id)}
                  className={
                    selectedProfessional === professional._id ? "active" : ""
                  }
                >
                  {professional.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="column column-right">
        {(selectedService || selectedProfessional) && (
          <>
            <h3 className="sub-title">{t("schedule_timings")}</h3>
            <div className="days-of-week">
              {daysOfWeek.map((day) => (
                <button
                  key={day}
                  className={`day-button ${
                    selectedDay === day ? "active" : ""
                  }`}
                  onClick={() => setSelectedDay(day)}
                >
                  {day}
                </button>
              ))}
            </div>
            <div className="hours-block">
              <h4 className="hours-title">{t("working_hours")}</h4>
              {hours[selectedDay]?.map((block, index) => (
                <div key={index} className="hour-block">
                  <span>
                    {block.start} - {block.end}
                  </span>
                  <FaEdit onClick={() => editBlock(index)} />
                  <FaTrash onClick={() => deleteBlock(index)} />
                </div>
              ))}
              <button className="add-button" onClick={openModal}>
                <FaPlus /> {t("add")}
              </button>
            </div>

            <div className="date-picker">
              <h4 className="date-picker-title">{t("select_dates")}</h4>
              <div className="selection-mode">
                <label>
                  <input
                    type="radio"
                    name="selectionMode"
                    value="single"
                    checked={selectionMode === "single"}
                    onChange={() => setSelectionMode("single")}
                  />{" "}
                  {t("single_day")}
                </label>
                <label>
                  <input
                    type="radio"
                    name="selectionMode"
                    value="range"
                    checked={selectionMode === "range"}
                    onChange={() => setSelectionMode("range")}
                  />{" "}
                  {t("date_range")}
                </label>
                <label>
                  <input
                    type="radio"
                    name="selectionMode"
                    value="alternate"
                    checked={selectionMode === "alternate"}
                    onChange={() => setSelectionMode("alternate")}
                  />{" "}
                  {t("alternate_days")}
                </label>
              </div>
              <div>
                <DatePicker
                  selected={
                    selectionMode === "single" ? selectedDates[0] : null
                  }
                  onChange={handleDateChange}
                  startDate={selectedDates[0]}
                  endDate={selectedDates[1]}
                  selectsRange={selectionMode === "range"}
                  inline
                  minDate={new Date()}
                  highlightDates={
                    selectionMode === "alternate" ? selectedDates : []
                  }
                  shouldCloseOnSelect={selectionMode === "single"}
                />
              </div>

              <button className="btn btn-primary" onClick={addAvailability}>
                {t("add_availability")}
              </button>
            </div>

            <div className="availability-list">
              <h4 className="availability-title">
                {t("current_availability")}
              </h4>
              <table className="availability-table">
                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <th>{t("time_slots")}</th>
                  </tr>
                </thead>
                <tbody>
                  {availability.map((avail, index) => (
                    <tr key={index}>
                      <td>{avail.date}</td>
                      <td>
                        {avail.timeSlots
                          ? avail.timeSlots.join(", ")
                          : t("no_time_slots")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="btn btn-success mb-buttons"
                onClick={saveSchedule}
              >
                {t("save_schedule")}
              </button>
            </div>
          </>
        )}

        <Modal show={isModalOpen} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {editingBlockIndex !== null
                ? t("edit_schedule")
                : t("add_schedule")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="start">
                <Form.Label>{t("start_time")}</Form.Label>
                <Form.Control
                  type="time"
                  name="start"
                  value={newBlock.start}
                  onChange={handleInputChange}
                  isInvalid={isPastTime(newBlock.start)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("start_end_required")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="end">
                <Form.Label>{t("end_time")}</Form.Label>
                <Form.Control
                  type="time"
                  name="end"
                  value={newBlock.end}
                  onChange={handleInputChange}
                  isInvalid={isPastTime(newBlock.end)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("start_end_required")}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              {t("cancel")}
            </Button>
            <Button variant="primary" onClick={saveBlock}>
              {t("save")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Schedule;
