import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './PaymentMethods.scss';

const PaymentMethods = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [newPaymentMethod, setNewPaymentMethod] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardHolderName: '',
    country: ''
  });
  const [errors, setErrors] = useState({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewPaymentMethod({
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      cardHolderName: '',
      country: ''
    });
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPaymentMethod({
      ...newPaymentMethod,
      [name]: value
    });
  };

  const validateFields = () => {
    const { cardNumber, expiryDate, cvv, cardHolderName, country } = newPaymentMethod;
    const newErrors = {};

    if (!cardNumber) newErrors.cardNumber = 'Card number is required';
    if (!expiryDate) newErrors.expiryDate = 'Expiry date is required';
    if (!cvv) newErrors.cvv = 'CVV is required';
    if (!cardHolderName) newErrors.cardHolderName = 'Card holder name is required';
    if (!country) newErrors.country = 'Country is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      setPaymentMethods([...paymentMethods, newPaymentMethod]);
      closeModal();
    }
  };

  return (
    <div className="payment-methods">
      <h3>Payment Methods</h3>
      <Button onClick={openModal}>Add Payment Method</Button>
      <ul>
        {paymentMethods.map((method, index) => (
          <li key={index}>
            {method.cardHolderName} - {method.cardNumber}
          </li>
        ))}
      </ul>
      
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="cardHolderName">
              <Form.Label>Card Holder Name</Form.Label>
              <Form.Control
                type="text"
                name="cardHolderName"
                value={newPaymentMethod.cardHolderName}
                onChange={handleInputChange}
                isInvalid={!!errors.cardHolderName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cardHolderName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="cardNumber">
              <Form.Label>Card Number</Form.Label>
              <Form.Control
                type="text"
                name="cardNumber"
                value={newPaymentMethod.cardNumber}
                onChange={handleInputChange}
                isInvalid={!!errors.cardNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cardNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="expiryDate">
              <Form.Label>Expiry Date</Form.Label>
              <Form.Control
                type="text"
                name="expiryDate"
                value={newPaymentMethod.expiryDate}
                onChange={handleInputChange}
                isInvalid={!!errors.expiryDate}
                placeholder="MM/YY"
              />
              <Form.Control.Feedback type="invalid">
                {errors.expiryDate}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="cvv">
              <Form.Label>CVV</Form.Label>
              <Form.Control
                type="text"
                name="cvv"
                value={newPaymentMethod.cvv}
                onChange={handleInputChange}
                isInvalid={!!errors.cvv}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cvv}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                value={newPaymentMethod.country}
                onChange={handleInputChange}
                isInvalid={!!errors.country}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentMethods;
