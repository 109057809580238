import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { updateClientData } from "../../../../Utils/dateAvailable/dataStorage";
import "./Profile.scss";
import { useSelector } from "react-redux";

const ClientProfile = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [editData, setEditData] = useState(user);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSave = () => {
    if (password && password !== confirmPassword) {
      setError(t("password_mismatch"));
      return;
    }

    updateClientData({ ...editData, password });
    setSuccessMessage(t("profile_updated_successfully"));
    setTimeout(() => {
      setSuccessMessage("");
    }, 3000);
  };

  return (
    <div className="client-profile">
      <h2>{t("client_profile")}</h2>
      <div className="profile-info">
        <div className="form-group">
          <label>{t("name")}</label>
          <input
            type="text"
            name="name"
            value={editData.name}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>{t("email")}</label>
          <input
            type="email"
            name="email"
            value={editData.email}
            onChange={handleInputChange}
            className="form-control"
            disabled
          />
        </div>
        <div className="form-group">
          <label>{t("phone")}</label>
          <input
            type="text"
            name="phone"
            value={editData.phone}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>{t("address")}</label>
          <input
            type="text"
            name="address"
            value={editData.address}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>{t("country")}</label>
          <input
            type="text"
            name="country"
            value={editData.country}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>{t("create_password")}</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>{t("confirm_password")}</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className="form-control"
          />
        </div>
        {error && <p className="error">{error}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
        <button className="btn btn-primary" onClick={handleSave}>
          {t("save")}
        </button>
      </div>
    </div>
  );
};

export default ClientProfile;
