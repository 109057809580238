import React from "react";
import { useTranslation } from "react-i18next";
import "./IndexFooter.scss";
import {
  Envelope,
  Facebook,
  GeoAlt,
  Instagram,
  Telephone,
  Tiktok,
  Youtube,
} from "react-bootstrap-icons";

const Footer = ({ companyData }) => {
  const { t } = useTranslation();

  return (
    <footer className="footer-user">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-4 col-md-6">
            <div className="footer-about mb-sm-5">
              <img src={companyData.logo} alt="logo" className="footer-logo" />
              <div className="footer-about-content">
                <p className="mt-4">{companyData.about}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="footer-contact">
              <h6 className="mb-3">
                {companyData.userText.footerContactTitle}
              </h6>
              <div className="info-container">
                <GeoAlt size={16} />
                {companyData.address}
              </div>
              <div className="info-container mt-1">
                <Telephone className="mt-1" size={16} />
                <a href={"tel:" + companyData.phone}>{companyData.phone}</a>
              </div>
              <div className="info-container mt-1">
                <Envelope className="mt-1" size={16} />
                <a href={"mailto:" + companyData.email}>{companyData.email}</a>
              </div>
              <ul className="rrss">
                {companyData.instagramLink && (
                  <li>
                    <a
                      href={companyData.instagramLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Instagram size={21} />
                    </a>
                  </li>
                )}

                {companyData.instagramLink && (
                  <li>
                    <a
                      href={companyData.facebookLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook size={21} />
                    </a>
                  </li>
                )}
                {companyData.tiktokLink && (
                  <li>
                    <a
                      href={companyData.tiktokLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Tiktok size={21} />
                    </a>
                  </li>
                )}
                {companyData.youtubeLink && (
                  <li>
                    <a
                      href={companyData.youtubeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Youtube size={21} />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12">
            <div className="copyright">
              <p className="text-small mb-0">
                {t("developed_by")} <a href="/login">Venture Global Services</a>{" "}
                &copy; 2024. {t("all_rights_reserved")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
