import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CalendarView from "../Appointments/CalendarView/CalendarView";
import AddAppointmentModal from "../Appointments/AddAppointmentModal/AddAppointmentModal";
import { useDispatch, useSelector } from "react-redux";
import { getAvailabilityByCompanyId } from "../../../../store/availabilitySlice";
import "./Appointments.scss";

const Appointments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("today");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newAppointment, setNewAppointment] = useState({
    client: "",
    email: "",
    phone: "",
    date: new Date(),
    service: "",
    associate: "",
  });
  const [errors, setErrors] = useState({});

  const { appointments } = useSelector((state) => state.appointments);
  const { services } = useSelector((state) => state.services);
  const { professionals, loading, error } = useSelector(
    (state) => state.professionals
  );
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      const availabilities = await dispatch(
        getAvailabilityByCompanyId(user._id)
      );
      console.log("appointments", availabilities);
    };

    fetchData();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const paginatedAppointments = appointments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(appointments.length / itemsPerPage);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewAppointment({
      client: "",
      email: "",
      phone: "",
      date: new Date(),
      service: "",
      associate: "",
    });
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment({
      ...newAppointment,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setNewAppointment({
      ...newAppointment,
      date: date,
    });
  };

  const validateFields = () => {
    const { client, email, phone, date, service, associate } = newAppointment;
    const newErrors = {};

    if (!client) newErrors.client = t("required_field");
    if (!email) newErrors.email = t("required_field");
    if (!phone) newErrors.phone = t("required_field");
    if (!date) newErrors.date = t("required_field");
    if (!service) newErrors.service = t("required_field");
    if (!associate) newErrors.associate = t("required_field");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      appointments.push(newAppointment);
      closeModal();
    }
  };

  const handleSelectSlot = ({ start, associate, service }) => {
    //la idea era levantar el modal con los datos del slot seleccionado
    console.log("handleSelectSlot", start, associate, service);
    // setNewAppointment({
    //   ...newAppointment,
    //   date: start,
    //   associate: associate.name,
    //   service: service,
    // });
    //setIsModalOpen(true);
  };

  const handleEdit = (appointment) => {
    setNewAppointment(appointment);
    setIsModalOpen(true);
  };

  return (
    <div className="appointments">
      <div className="tabs">
        <button
          className={`tab ${activeTab === "today" ? "active" : ""}`}
          onClick={() => setActiveTab("today")}
        >
          {t("appointments_today")}
        </button>
        <button
          className={`tab ${activeTab === "upcoming" ? "active" : ""}`}
          onClick={() => setActiveTab("upcoming")}
        >
          {t("upcoming_appointments")}
        </button>
      </div>

      <button className="btn btn-primary mb-3" onClick={openModal}>
        {t("add_appointment")}
      </button>

      {activeTab === "today" ? (
        <>
          <table className="appointments-table">
            <thead>
              <tr>
                <th>{t("client_data")}</th>
                <th>{t("appointment_date")}</th>
                <th>{t("service")}</th>
                <th>{t("actions")}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedAppointments.map((appointment, index) => {
                const appointmentDate = new Date(appointment.date);
                const now = new Date();
                const isFuture = appointmentDate > now;

                return (
                  <tr key={index}>
                    <td>
                      <p>{appointment.name}</p>
                      <p>{appointment.email}</p>
                      <p>{appointment.phone}</p>
                    </td>
                    <td>{new Date(appointment.date).toLocaleDateString()}</td>
                    <td>{appointment.serviceName}</td>
                    <td>
                      <button className="btn btn-danger">{t("cancel")}</button>
                      {isFuture && (
                        <button
                          className="btn btn-secondary m-1"
                          onClick={() => handleEdit(appointment)}
                        >
                          {t("edit")}
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="btn btn-primary"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {t("previous")}
            </button>
            <span>{`${currentPage} / ${totalPages}`}</span>
            <button
              className="btn btn-primary"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {t("next")}
            </button>
          </div>
        </>
      ) : (
        <CalendarView
          appointments={appointments}
          onBlockHour={handleSelectSlot}
        />
      )}

      <AddAppointmentModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        newAppointment={newAppointment}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        handleSubmit={handleSubmit}
        errors={errors}
        services={services}
        associates={professionals}
      />
    </div>
  );
};

export default Appointments;
