import React from 'react';
import { useTranslation } from 'react-i18next';
import './Success.scss';

const Success = ({ setView }) => {
  const { t } = useTranslation();

  const handleLoginClick = () => {
    setView('login');
  };

  return (
    <div className="account-success">
      <div className="success-card">
        <div className="success-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-check-circle"
            viewBox="0 0 16 16"
          >
            <path d="M15.854 3.646a.5.5 0 0 0-.708 0L7 11.793 4.354 9.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l8-8a.5.5 0 0 0 0-.708z" />
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0-1A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
            />
          </svg>
        </div>
        <h2>{t('account_created')}</h2>
        <p>{t('account_success_message')}</p>
        <button className="btn btn-primary" onClick={handleLoginClick}>
          {t('login_button')}
        </button>
      </div>
    </div>
  );
};

export default Success;
