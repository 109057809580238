import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import "./Services.scss";

// Configurar el elemento principal para react-modal
Modal.setAppElement("#root");

const Services = () => {
  const { t } = useTranslation();
  const { services } = useSelector((state) => state.services);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingService, setEditingService] = useState({
    name: "",
    duration: "",
    serviceType: "",
    online: false,
    meetLink: "",
    price: "",
    description: "",
    imageUrl: "",
    companyId: "",
  });
  const [errors, setErrors] = useState({});

  const openModal = (
    service = {
      name: "",
      duration: "",
      serviceType: "",
      online: false,
      meetLink: "",
      price: "",
      description: "",
      imageUrl: "",
      companyId: "",
    }
  ) => {
    setEditingService(service);
    setErrors({});
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setEditingService({
      name: "",
      duration: "",
      serviceType: "",
      online: false,
      meetLink: "",
      price: "",
      description: "",
      imageUrl: "",
      companyId: "",
    });
    setErrors({});
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditingService((prevService) => ({
      ...prevService,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!editingService.name) newErrors.name = t("required_field");
    if (!editingService.duration) newErrors.duration = t("required_field");
    if (!editingService.serviceType)
      newErrors.serviceType = t("required_field");
    if (editingService.online && !editingService.meetLink)
      newErrors.meetLink = t("required_field");
    if (!editingService.price) newErrors.price = t("required_field");
    if (!editingService.description)
      newErrors.description = t("required_field");
    if (!editingService.imageUrl) newErrors.imageUrl = t("required_field");
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const generateMeetLink = () => {
    return "http://example.com/meet/" + Math.random().toString(36).substr(2, 9);
  };

  const handleSave = () => {
    if (validateFields()) {
      if (editingService._id) {
        // dispatch(updateService(editingService));
      } else {
        // dispatch(addService(editingService));
      }
      closeModal();
    }
  };

  useEffect(() => {
    if (editingService.online && !editingService.meetLink) {
      setEditingService((prevService) => ({
        ...prevService,
        meetLink: generateMeetLink(),
      }));
    }
  }, [editingService.online]);

  return (
    <div className="services">
      <h2>{t("services")}</h2>
      <button className="add-service-button" onClick={() => openModal()}>
        <FaPlusCircle /> {t("add_service")}
      </button>
      <table className="services-table">
        <thead>
          <tr>
            <th>{t("name")}</th>
            <th>{t("duration")}</th>
            <th>{t("price")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {services.map((service, index) => (
            <tr key={index}>
              <td>{service.name}</td>
              <td>{service.duration}</td>
              <td>{service.price}</td>
              <td>
                <button
                  className="edit-button"
                  onClick={() => openModal(service)}
                >
                  <FaEdit /> {t("edit")}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Service"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <h2>{editingService._id ? t("edit_service") : t("add_service")}</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">{t("name")}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={editingService.name}
              onChange={handleInputChange}
              className={errors.name ? "is-invalid" : ""}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="duration">{t("duration")}</label>
            <input
              type="number"
              id="duration"
              name="duration"
              value={editingService.duration}
              onChange={handleInputChange}
              className={errors.duration ? "is-invalid" : ""}
            />
            {errors.duration && (
              <div className="invalid-feedback">{errors.duration}</div>
            )}
          </div>
          <div className="form-group">
            <label>{t("service_type")}</label>
            <div className="radio-group">
              <label className="radio-label">
                <input
                  type="radio"
                  name="serviceType"
                  value="presencial"
                  checked={editingService.serviceType === "presencial"}
                  onChange={handleInputChange}
                />
                {t("presencial")}
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="serviceType"
                  value="online"
                  checked={editingService.serviceType === "online"}
                  onChange={handleInputChange}
                />
                {t("online")}
              </label>
            </div>
            {errors.serviceType && (
              <div className="invalid-feedback">{errors.serviceType}</div>
            )}
          </div>
          {editingService.serviceType === "online" && (
            <div className="form-group">
              <label htmlFor="meetLink">{t("meet_link")}</label>
              <input
                type="text"
                id="meetLink"
                name="meetLink"
                value={editingService.meetLink}
                onChange={handleInputChange}
                className={errors.meetLink ? "is-invalid" : ""}
              />
              {errors.meetLink && (
                <div className="invalid-feedback">{errors.meetLink}</div>
              )}
            </div>
          )}
          <div className="form-group">
            <label htmlFor="price">{t("price")}</label>
            <input
              type="number"
              id="price"
              name="price"
              value={editingService.price}
              onChange={handleInputChange}
              className={errors.price ? "is-invalid" : ""}
            />
            {errors.price && (
              <div className="invalid-feedback">{errors.price}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="description">{t("description")}</label>
            <textarea
              id="description"
              name="description"
              value={editingService.description}
              onChange={handleInputChange}
              className={errors.description ? "is-invalid" : ""}
            />
            {errors.description && (
              <div className="invalid-feedback">{errors.description}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="imageUrl">{t("image_url")}</label>
            <input
              type="text"
              id="imageUrl"
              name="imageUrl"
              value={editingService.imageUrl}
              onChange={handleInputChange}
              className={errors.imageUrl ? "is-invalid" : ""}
            />
            {errors.imageUrl && (
              <div className="invalid-feedback">{errors.imageUrl}</div>
            )}
          </div>
          <div className="modal-buttons">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              {t("save")}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              {t("cancel")}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Services;
