import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Services.scss";
import { Button } from "react-bootstrap";
import { Cash, Clock } from "react-bootstrap-icons";
import AddAppointmentForm from "../AddAppointmentForm/AddAppointmentForm";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../../auth/AuthContext";

const Services = ({ companyData }) => {
  // const [companyServices, setCompanyServices] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  const [isXSmallView, setIsXSmallView] = useState(true);
  const [isSmallView, setIsSmallView] = useState(null);

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState({});

  const handleBookAppointment = (item) => {
    item.idAssociate = "";
    console.log("soy item", item);
    setSelectedService(item);
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("companyData", companyData.companyData);
    if (companyData.services.length <= 2) {
      setIsXSmallView(true);
    }
    if (companyData.services.length > 2 && companyData.services.length <= 4) {
      setIsSmallView(true);
    }
  }, []);

  return (
    <section className="services-section">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>{companyData.userText.servicesTitle}</h2>
          </div>
        </div>
        <div className="row">
          {companyData.services &&
            companyData.services.map((item, index) => (
              <div
                className={`mb-4 ${
                  isXSmallView
                    ? "col-md-6 service-xl"
                    : isSmallView
                    ? "col-md-4 service-md"
                    : "col-md-6 col-lg-4 service-sm"
                }`}
                key={index}
              >
                <div className="service-container">
                  <div>
                    <img
                      className="service-image"
                      src={item.imageUrl}
                      alt={item.name}
                    />
                    <h3>{item.name}</h3>
                    <p className="service-duration">
                      {t("duration")}: {item.duration} {t("minutes")}
                    </p>
                    <p className="service-description">{item.description}</p>
                  </div>
                  <div className="service-footer">
                    <div className="service-price">
                      <span>$</span>
                      <h3>{item.price}</h3>
                    </div>
                    <Button
                      className="service-button btn btn-primary"
                      onClick={() => handleBookAppointment(item)}
                    >
                      {companyData.userText.servicesButton}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("add_appointment_for")} {selectedService.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddAppointmentForm serviceData={selectedService} />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Services;
