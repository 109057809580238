// ConfigEditor.js
import React from "react";

const ConfigEditor = ({ companyData, setCompanyData, sentData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name, value);
    setCompanyData({
      ...companyData,
      [name]: value,
    });
  };

  const handleChangeByUserText = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      userText: {
        ...companyData.userText,
        [name]: value,
      },
    });
  };

  const handleStyleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      styles: {
        ...companyData.styles,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const reader = new FileReader();
    reader.onloadend = () => {
      setCompanyData({
        ...companyData,
        [name]: reader.result,
      });
    };
    reader.readAsDataURL(files[0]);
  };
  const handleSave = (e) => {
    e.preventDefault();
    sentData(companyData); // Llamamos a la función pasada como prop
  };
  return (
    <div className="config-editor">
      <h2>Configure Home Page</h2>
      <form>
        <div>
          <label>
            Company Name: <span>{companyData.name}</span>
          </label>
        </div>
        <div className="mt-4">
          <div className="mt-2">
            <h5>Configure Header</h5>
          </div>
          <div className="mt-2">
            <div className="mt-1">
              <label>Banner Title:</label>
              <input
                type="text"
                name="bannerTitle"
                value={companyData.userText.bannerTitle}
                onChange={handleChangeByUserText}
              />
            </div>
            <div className="mt-1">
              <label>Logo:</label>
              <input type="file" name="logo" onChange={handleFileChange} />
            </div>
            <div className="mt-1">
              <label>Phone:</label>
              <input
                type="text"
                name="phone"
                value={companyData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="mt-1">
              <label>Email:</label>
              <span className="ml-3">{companyData.email}</span>
            </div>
            <div className="mt-1">
              <label>Boton background color:</label>
              <input
                type="color"
                name="tn-background-color"
                value={companyData.scssVariables["btn-background-color"]}
                onChange={handleStyleChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="mt-2">
            <h5>Configure Banner</h5>
          </div>
          <div className="mt-2">
            <div className="mt-1">
              <label>Button Text:</label>
              <input
                type="text"
                name="headerButton"
                value={companyData.userText.headerButton}
                onChange={handleChangeByUserText}
              />
            </div>

            <div className="mt-1">
              <label>Banner Title:</label>
              <input
                type="text"
                name="bannerTitle"
                value={companyData.userText.bannerTitle}
                onChange={handleChangeByUserText}
              />
            </div>

            <div className="mt-1">
              <label>Banner subTitle:</label>
              <input
                type="text"
                name="bannerSubtitle"
                value={companyData.userText.bannerSubtitle}
                onChange={handleChangeByUserText}
              />
            </div>

            <div className="mt-1">
              <label>Banner bannerButton:</label>
              <input
                type="text"
                name="bannerButton"
                value={companyData.userText.bannerButton}
                onChange={handleChangeByUserText}
              />
            </div>

            <div className="mt-1">
              <label>Logo:</label>
              <input
                type="file"
                name="bannerImag"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="mt-2">
            <h5>Configure Services</h5>
          </div>
          <div className="mt-2">
            <div className="mt-1">
              <label>servicesTitle:</label>
              <input
                type="text"
                name="servicesTitle"
                value={companyData.userText.servicesTitle}
                onChange={handleChangeByUserText}
              />
            </div>
            <div className="mt-1">
              <label>servicesButton:</label>
              <input
                type="text"
                name="servicesButton"
                value={companyData.userText.servicesButton}
                onChange={handleChangeByUserText}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="mt-2">
            <h5>Configure Map</h5>
          </div>
          <div className="mt-2">
            <div className="mt-1">
              <label>Map Title:</label>
              <input
                type="text"
                name="mapTitle"
                value={companyData.userText.mapTitle}
                onChange={handleChangeByUserText}
              />
              <input
                type="text"
                name="address"
                value={companyData.address}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="mt-2">
            <h5>Configure Footer</h5>
          </div>
          <div className="mt-2">
            <div className="mt-1">
              <label>about:</label>
              <input
                type="text"
                name="about"
                value={companyData.about}
                onChange={handleChange}
              />
            </div>
            <div className="mt-1">
              <label>Footer Title:</label>
              <input
                type="text"
                name="footerContactTitle"
                value={companyData.userText.footerContactTitle}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>

      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default ConfigEditor;
