import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createProfessional,
  updateProfessional,
} from "../../../../../store/professionalSlice";
import { fetchServices } from "../../../../../store/servicesSlice";
import { FaUpload } from "react-icons/fa";
import "./professionalForm.scss";

const ProfessionalForm = ({
  isModalOpen,
  closeModal,
  professional,
  isEdit,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [services, setServices] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [imagePreview, setImagePreview] = useState(
    professional?.profileImage || ""
  );
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    rol: "",
    email: "",
    password: "",
    phone: "",
    profileImage: "",
    companyId: "",
    serviceIds: [],
    availability: [],
  });

  useEffect(() => {
    if (isEdit && professional) {
      setFormData(professional);
      getAllServices();
    } else {
      setFormData({
        name: "",
        rol: "Professional",
        email: "",
        password: "",
        phone: "",
        profileImage: "",
        companyId: user._id,
        serviceIds: [],
        availability: [],
      });
    }
  }, [professional, isEdit]);

  const getAllServices = async () => {
    const { payload } = await dispatch(fetchServices(user._id));
    setServices(payload);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getServicesToProfessional = () => {
    const uni = services.filter((service) =>
      formData.serviceIds.includes(service._id)
    );
    return uni;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    try {
      if (isEdit) {
        await dispatch(updateProfessional(formData));
      } else {
        await dispatch(createProfessional(formData));
      }
      onSave();
      closeModal();
    } catch (error) {
      console.log("Error creating professional", error);
      setError(t("error_occurred"));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setError(t("file_too_large"));
      } else {
        setError("");
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
          setFormData({ ...formData, profileImage: reader.result });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? t("edit_professional") : t("create_professional")}
        </Modal.Title>
      </Modal.Header>
      {error && <Alert variant="danger">{error}</Alert>}
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="form-content">
            <div className="image-section">
              <Form.Group controlId="profileImage">
                <Form.Label>{t("profile_image")}</Form.Label>
                <div className="custom-file-upload">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    required={!isEdit}
                    className="file-input"
                  />
                  <Button as="span" variant="outline-secondary">
                    <FaUpload /> {t("upload_image")}
                  </Button>
                </div>
                {error && <Alert variant="danger">{error}</Alert>}
                {imagePreview && imagePreview.length > 0 && (
                  <div className="image-preview">
                    <img src={imagePreview} alt="Profile" />
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="form-section">
              <Form.Group controlId="name">
                <Form.Label>{t("name")}</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={isEdit}
                  required
                />
              </Form.Group>
              {!isEdit && (
                <Form.Group controlId="password">
                  <Form.Label>{t("password")}</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required={!isEdit}
                  />
                </Form.Group>
              )}
              <Form.Group controlId="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              {isEdit && services && (
                <div className="mt-4">
                  <Form.Label>{t("services")}</Form.Label>
                  <div>
                    {getServicesToProfessional().map((service) => (
                      <label key={service._id}>{service.name}</label>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <Button variant="primary" type="submit">
              {t("save")}
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              {t("cancel")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProfessionalForm;
