import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Profile.scss';
import Tooltip from '../../../Utils/Tooltip/Tooltip';

const Profile = ({ data, onNext }) => {
  const { t } = useTranslation();
  const [profileData, setProfileData] = useState({
    name: '',
    serviceArea: '',
    phone: '',
    address: '',
  });

    useEffect(() => {
      console.log('data', data);
    if (data) {
      setProfileData(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(profileData);
  };

  return (
    <div className="onboarding-profile">
   
      <div className="content">
        <form className="form-container" onSubmit={handleSubmit}>
          <h2 className="form-title">{t('profile')}</h2>
          <div className="form-group">
            <label htmlFor="name">{t('name_or_business')}
            
            <Tooltip text={t('tooltip_first_name')} /></label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder={t('name_or_business')}
              value={profileData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="serviceArea">{t('service_area')}
            </label>
            <input
              type="text"
              className="form-control"
              id="serviceArea"
              name="serviceArea"
              placeholder={t('service_area')}
              value={profileData.serviceArea}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">{t('phone')}</label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              placeholder={t('phone')}
              value={profileData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">{t('address')}</label>
            <input
              type="text"
              className="form-control"
              id="address"
              name="address"
              placeholder={t('address')}
              value={profileData.address}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100 mt-3">{t('continue')}</button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
