// Tooltip.js
import React from 'react';
import './Tooltip.scss';

const Tooltip = ({ text }) => (
  <div className="tooltip">
    <span className="tooltiptext">{text}</span>
  </div>
);

export default Tooltip;
