// src/axiosInstance.js
import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: window.location.host.match('localhost') ? "http://localhost:3001" : "https://api.vens.app/",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
