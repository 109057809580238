import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addServiceToProfessional } from "../../../../../store/professionalSlice";

const ServiceAssignmentForm = ({
  isModalOpen,
  closeModal,
  professional,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.services);
  const [selectedServices, setSelectedServices] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    console.log("value", value);
    if (checked) {
      setSelectedServices([...selectedServices, value]);
    } else {
      setSelectedServices(
        selectedServices.filter((service) => service !== value)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("selectedServices", selectedServices);
    const obj = {
      id: professional._id,
      companyId: user._id,
      serviceId: selectedServices,
    };
    console.log("enviare", obj);
    const resp = await dispatch(addServiceToProfessional(obj));
    console.log("resp", resp);
    // onSave();
    // closeModal();
  };

  return (
    <Modal show={isModalOpen} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("assign_services")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {services.map((service) => (
            <Form.Check
              key={service._id}
              type="checkbox"
              label={service.name}
              value={service._id}
              onChange={(e) => handleServiceChange(e)}
            />
          ))}
          <Button variant="primary" type="submit" className="mt-3">
            {t("save")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceAssignmentForm;
