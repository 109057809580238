import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaUser, FaCalendarAlt, FaMoneyBillWave } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointments } from "../../../../store/appointmentsSlice";
import { fetchServices } from "../../../../store/servicesSlice";
import fetchProfessionals from "../../../../store/professionalSlice";
import { getAvailabilityByCompanyId } from "../../../../store/availabilitySlice";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import "./Dashboard.scss";

// Registrar componentes de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const ClientDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [totalAppointmentsToday, setTotalAppointmentsToday] = useState(0);
  const [totalAppointmentsMonth, setTotalAppointmentsMonth] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    const getAppointments = async () => {
      try {
        const { payload } = await dispatch(fetchAppointments(user._id));
        if (payload) {
          setData(payload);
        }
        await dispatch(fetchServices(user._id));
        dispatch(getAvailabilityByCompanyId(user._id));
        if (user.professional) {
          await dispatch(fetchProfessionals(user._id));
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoadingData(false);
      }
    };

    getAppointments();
  }, [dispatch, user._id]);

  useEffect(() => {
    const calculateDataToGraffica = () => {
      const today = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
      const currentMonth = new Date().getMonth(); // 0-based, 0 = January, 11 = December
      const currentYear = new Date().getFullYear();

      let todayCount = 0;
      let monthCount = 0;
      let revenue = 0;

      data.forEach((appointment) => {
        const appointmentDate = new Date(appointment.date);
        const appointmentMonth = appointmentDate.getMonth();
        const appointmentYear = appointmentDate.getFullYear();
        const appointmentDateStr = appointmentDate.toISOString().split("T")[0];

        if (appointmentDateStr === today) {
          todayCount += 1;
        }

        if (
          appointmentMonth === currentMonth &&
          appointmentYear === currentYear
        ) {
          monthCount += 1;
          // Assuming `appointment.price` is available when the endpoint is ready
          revenue += appointment.price || 0; // Update this when the endpoint is ready
        }
      });

      setTotalAppointmentsToday(todayCount);
      setTotalAppointmentsMonth(monthCount);
      setTotalRevenue(revenue);
    };

    if (data.length > 0) {
      calculateDataToGraffica();
    }
  }, [data]);

  return (
    <div className="client-dashboard">
      <div className="summary-cards row">
        <div className="card card-appointments-today col-md-4">
          <h4>
            <FaCalendarAlt className="icon" /> {t("appointments_today")}
          </h4>
          <p>{totalAppointmentsToday}</p>
          <p>{new Date().toLocaleDateString()}</p>
        </div>
        <div className="card card-total-appointments col-md-4">
          <h4>
            <FaCalendarAlt className="icon" /> {t("total_appointments_month")}
          </h4>
          <p>{totalAppointmentsMonth}</p>
          <p>{t(new Date().toLocaleString("default", { month: "long" }))}</p>
        </div>
        <div className="card card-monthly-revenue col-md-4">
          <h4>
            <FaMoneyBillWave className="icon" /> {t("monthly_revenue")}
          </h4>
          <p>${totalRevenue}</p>
          <p>{t(new Date().toLocaleString("default", { month: "long" }))}</p>
        </div>
      </div>
      {data.length > 0 && (
        <>
          <div className="appointments-today">
            <h3>{t("appointments_today")}</h3>
            <input placeholder={t("search")} className="search-input" />
            {loadingData ? (
              <p>{t("loading")}</p>
            ) : (
              <table className="appointments-table">
                <thead>
                  <tr>
                    <th>{t("client_data")}</th>
                    <th>{t("appointment_date")}</th>
                    <th>{t("service")}</th>
                    <th>{t("time")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((appointment, index) => (
                    <tr key={index}>
                      <td>
                        <p>
                          <FaUser className="icon" /> {appointment.client}
                          <label>{appointment.name}</label> -{" "}
                          <label>{appointment.phone}</label>
                        </p>
                      </td>
                      <td>{new Date(appointment.date).toLocaleDateString()}</td>
                      <td>{appointment.service}</td>
                      <td>{appointment.time}</td>
                      <td>
                        <button className="btn btn-danger">
                          {t("cancel")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ClientDashboard;
