import React, { useState, useEffect } from "react";
import Header from "../Client/Header/Header";
import Footer from "../Client/Footer/Footer";
import Sidebar from "../Client/Sidebar/Sidebar";
import ClientDashboard from "./ClientDashboard/Dashboard/Dashboard";
import Appointments from "./ClientDashboard/Appointments/Appointments";
import TimeBlocks from "./ClientDashboard/TimeBlocks/TimeBlocks";
import "./index.scss";
import Schedule from "./ClientDashboard/Schedule/Schedule";
import Profile from "./ClientDashboard/Profile/Profile";
import Services from "./ClientDashboard/Services/Services";
import PaymentMethods from "./ClientDashboard/PaymentMethods/PaymentMethods";
import Password from "./ClientDashboard/Password/Password";
import { useSelector } from "react-redux";
import ClientLogin from "./ClientDashboard/Login/Login";
import HomePage from "./HomePage/HomePage";
import Professional from "./ClientDashboard/Professional/Professional";
import ProfessionalManagement from "../Client/ClientDashboard/Professional/Professional";

const DashboardIndex = () => {
  const { loading, error, isAuthenticated, user } = useSelector(
    (state) => state.auth
  );
  const [currentView, setCurrentView] = useState("home");
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(
    window.innerWidth > 768
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    const mobile = window.innerWidth <= 768;
    setIsMobile(mobile);
    if (mobile) {
      setIsSidebarExpanded(false);
    }
  };

  useEffect(() => {
    console.log("user", user);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const renderContent = () => {
    console.log("currentView", currentView);
    switch (currentView) {
      case "home":
        return isAuthenticated ? <ClientDashboard /> : <ClientLogin />;
      case "appointments":
        return isAuthenticated ? <Appointments /> : <ClientLogin />;
      case "schedule":
        return isAuthenticated ? <Schedule /> : <ClientLogin />;
      case "time_blocks":
        return isAuthenticated ? <TimeBlocks /> : <ClientLogin />;
      case "profile":
        return isAuthenticated ? <Profile /> : <ClientLogin />;
      case "services":
        return isAuthenticated ? <Services /> : <ClientLogin />;
      case "payment_methods":
        return isAuthenticated ? <PaymentMethods /> : <ClientLogin />;
      case "change_password":
        return isAuthenticated ? <Password /> : <ClientLogin />;
      case "HomePage":
        return isAuthenticated ? <HomePage /> : <HomePage />;
      case "professional":
        return isAuthenticated ? <Professional /> : <Professional />;
      case "professional_management":
        return isAuthenticated ? <ProfessionalManagement /> : <ClientLogin />;
      case "logout":
        console.log("logout");
        //logout();
        return <ClientLogin />;
      default:
        return <ClientDashboard />;
    }
  };

  return (
    <div
      className={`dashboard-index ${isMobile ? "mobile" : ""} ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      <Header />
      <div className="dashboard-content">
        <Sidebar
          currentView={currentView}
          setCurrentView={setCurrentView}
          toggleSidebar={toggleSidebar}
          isExpanded={isSidebarExpanded}
        />
        <main className="main-content">{renderContent()}</main>
      </div>
      {!isMobile && <Footer />}
    </div>
  );
};

export default DashboardIndex;
