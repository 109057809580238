import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchSidebar from '../SearchSidebar/SearchSidebar';
import AsociateWidget from '../AsociateWidget/AsociateWidget';
import { getAllServices, findServicesById } from '../../../Utils/dateAvailable/dataStorage';
import './Search.scss';

const Search = () => {
  const { id } = useParams();
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    
    const selectedService = id ? findServicesById(id) : getAllServices();
   console.log('selectedService', selectedService);
    if (selectedService) { 
       setFilteredServices(selectedService);
    }

    console.log('filteredServices', filteredServices);
  }, [id]); 

  return (
    <div className="main-wrapper">
      <div className="content">
        <div className="container-fluid">
          {filteredServices.length > 0 && (
            <div className="row">
              <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                <div className="stickyside">
                  <SearchSidebar />
                </div>
              </div>
              <div className="col-md-12 col-lg-8 col-xl-9">
                {filteredServices.map((service, index) => (
                  <AsociateWidget key={index} item={service} />
                ))}
                <div className="load-more text-center">
                  <button className="btn btn-primary btn-sm">Load More</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
