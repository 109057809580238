import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { Calendar4Week, Cash, Clock } from "react-bootstrap-icons";
import "./AddAppointmentForm.scss";
// import axiosInstance from "../../../store/axiosConfig";

const AddAppointmentForm = ({ serviceData }) => {
  const { t } = useTranslation();
  const [availableTimes, setAvailableTimes] = useState({});
  const [errors, setErrors] = useState({});
  const [newAppointment, setNewAppointment] = useState({
    client: "",
    email: "",
    phone: "",
    date: new Date(),
    serviceId: serviceData.serviceId,
    associateId: serviceData.associateId,
  });

  const validateFields = () => {
    const { client, email, phone, date, service, associate } = newAppointment;
    const newErrors = {};

    if (!client) newErrors.client = t("required_field");
    if (!email) newErrors.email = t("required_field");
    if (!phone) newErrors.phone = t("required_field");
    if (!date) newErrors.date = t("required_field");
    if (!service) newErrors.service = t("required_field");
    if (!associate) newErrors.associate = t("required_field");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      console.log("llegue al handlesubmit");
      // appointments.push(newAppointment);
      // closeModal();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment({
      ...newAppointment,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setNewAppointment({
      ...newAppointment,
      date: date,
    });
  };

  const filterTime = (time) => {
    const selectedDate = new Date(time);
    const day = selectedDate
      .toLocaleDateString("en-us", { weekday: "long" })
      .toLowerCase();
    const timeString =
      selectedDate.getHours().toString().padStart(2, "0") +
      ":" +
      selectedDate.getMinutes().toString().padStart(2, "0");
    return availableTimes[day]?.includes(timeString);
  };

  const filterDate = (date) => {
    const day = date
      .toLocaleDateString("en-us", { weekday: "long" })
      .toLowerCase();
    return availableTimes[day] && availableTimes[day].length > 0;
  };

  const [serviceAssociates, setServiceAssociates] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // axiosInstance
    //   .get(
    //     `/associates/service/${serviceData.serviceId}/company/66885b2b057e3327ab7e53fd`
    //   )
    //   .then((response) => {
    //     setServiceAssociates(response.data);
    //     setLoading(false);
    //     console.log("dataaaa", response.data);
    //   })
    //   .catch((error) => {
    //     setError(error);
    //     setLoading(false);
    //   });
  }, []);

  return (
    <Form className="form-appointment" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="client">
        <Form.Label>{t("name")}</Form.Label>
        <Form.Control
          type="text"
          name="client"
          placeholder="Ingresa tu nombre!!"
          value={newAppointment.client}
          onChange={handleInputChange}
          isInvalid={!!errors.client}
        />
        <Form.Control.Feedback type="invalid">
          {errors.client}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>{t("email")}</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Ingresa tu correo electrónico!!"
          value={newAppointment.email}
          onChange={handleInputChange}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="phone">
        <Form.Label>{t("phone")}</Form.Label>
        <Form.Control
          type="text"
          name="phone"
          placeholder="Ingresa tu teléfono!!"
          value={newAppointment.phone}
          onChange={handleInputChange}
          isInvalid={!!errors.phone}
        />
        <Form.Control.Feedback type="invalid">
          {errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
      {/* <Form.Group controlId="service">
            <Form.Label>{t('service')}</Form.Label>
            <Form.Control
              as="select"
              name="service"
              value={newAppointment.serviceId}
              onChange={handleInputChange}
              isInvalid={!!errors.service}
            >
              <option value="">{t('select_service')}</option>
              {services.map((service, index) => (
                <option key={index} value={service.id}>
                  {service.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.service}</Form.Control.Feedback>
          </Form.Group> */}
      <Form.Group className="mb-3" controlId="associate">
        <Form.Label>{t("associate")}</Form.Label>
        <Form.Select
          name="associate"
          onChange={handleInputChange}
          isInvalid={!!errors.associate}
          aria-label="Default select example"
        >
          <option>{t("select_associate")}</option>
          {/* {associates.map((associate, index) => (
            <option key={index} value={associate.id}>
              {associate.name}
            </option>
          ))} */}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors.associate}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="date">
        <Form.Label>{t("date/time")}</Form.Label>
        <div className="date-selector">
          <DatePicker
            selected={newAppointment.date}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={30}
            dateFormat="MMMM d, yyyy h:mm aa"
            timeCaption={t("time")}
            minDate={new Date()}
            filterDate={filterDate}
            filterTime={filterTime}
            className={`form-control ${errors.date ? "is-invalid" : ""}`}
          />
          <Calendar4Week className="calendar-icon" />
        </div>
        <Form.Control.Feedback type="invalid">
          {errors.date}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="footer-container">
        <div className="info-service-container">
          <div className="info-service">
            <Cash /> $ {serviceData.price}
          </div>
          <div className="info-service">
            <Clock /> {serviceData.duration} {t("minutes")}
          </div>
        </div>
        <Button variant="primary" type="submit">
          {t("save")}
        </Button>
      </div>
    </Form>
  );
};

export default AddAppointmentForm;
