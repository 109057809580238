// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { authAPI } from "../services/api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Restaurar el estado de autenticación desde localStorage al cargar la aplicación
    const storedUser = null; //localStorage.getItem("user");
    const storedToken = null; //localStorage.getItem("token");
    if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (email, password) => {
    try {
      console.log("login vien al AUTCONTEXT", email, password);
      const response = await authAPI.login({ email, password });
      if (response.data) {
        const { user, token } = response.data;
        setIsAuthenticated(true);
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user)); // Almacenar el usuario en localStorage
        localStorage.setItem("token", token); // Almacenar el token en localStorage
      } else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("user"); // Eliminar el usuario de localStorage
    localStorage.removeItem("token"); // Eliminar el token de localStorage
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
