import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Schedule.scss';

const Schedule = ({ data, onNext }) => {
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState('L');
  const [schedule, setSchedule] = useState({
    L: [{ start: '08:00', end: '12:30' }, { start: '13:30', end: '18:30' }],
    M: [],
    X: [],
    J: [],
    V: [],
    S: [],
    D: [],
  });
  const [newBlock, setNewBlock] = useState({ start: '', end: '' });
  const [isEditing, setIsEditing] = useState(false);

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleBlockChange = (e) => {
    const { name, value } = e.target;
    setNewBlock((prevBlock) => ({ ...prevBlock, [name]: value }));
  };

  const handleAddBlock = (e) => {
    e.preventDefault();
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [selectedDay]: [...prevSchedule[selectedDay], newBlock],
    }));
    setNewBlock({ start: '', end: '' });
    setIsEditing(false);
  };

  const handleRemoveBlock = (index) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [selectedDay]: prevSchedule[selectedDay].filter((_, i) => i !== index),
    }));
  };

  const handleContinue = () => {
    onNext(schedule);
  };

  return (
    <div className="schedule">
      <h2>{t('schedule')}</h2>
      <p>{t('configure_availability')}</p>
      <div className="days">
        {['L', 'M', 'X', 'J', 'V', 'S', 'D'].map((day) => (
          <button
            key={day}
            className={`day ${selectedDay === day ? 'selected' : ''}`}
            onClick={() => handleDayClick(day)}
          >
            {day}
          </button>
        ))}
      </div>
      <div className="time-blocks">
        <h3>{t('time_blocks')}</h3>
        <div className="blocks">
          {schedule[selectedDay].map((block, index) => (
            <div key={index} className="block">
              {`${block.start} - ${block.end}`}
              <button className="remove" onClick={() => handleRemoveBlock(index)}>X</button>
            </div>
          ))}
        </div>
        {isEditing ? (
          <form onSubmit={handleAddBlock} className="add-block-form">
            <input
              type="time"
              name="start"
              value={newBlock.start}
              onChange={handleBlockChange}
              required
            />
            <input
              type="time"
              name="end"
              value={newBlock.end}
              onChange={handleBlockChange}
              required
            />
            <button type="submit" className="btn btn-primary">{t('add')}</button>
          </form>
        ) : (
          <button className="edit" onClick={handleEditClick}> {schedule[selectedDay].length === 0 ? t('add') : t('edit')}</button>
        )}
      </div>
      <button className="btn btn-primary w-100 mt-3" onClick={handleContinue}>{t('continue')}</button>
    </div>
  );
};

export default Schedule;
