import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { useAuth } from "../../auth/AuthContext";
import AddClient from "../Admin/AddClient/AddClient";
import logo from "../../assets/img/logo-venture.png";
import "./index.scss";

const AdminIndex = () => {
  const { t } = useTranslation();
  const { logout, isAuthenticated } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.pathname = "/login"; // Redirigir al login si no está autenticado
    }
  }, [isAuthenticated]);

  const handleOpenModal = (client = null) => {
    setCurrentClient(client);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleSaveClient = (clientInfo) => {
    // Aquí puedes manejar la lógica para guardar el cliente (ya sea crear o editar)
    console.log("Client Info:", clientInfo);
    handleCloseModal();
  };

  const handleLogout = () => {
    logout();
    window.location.pathname = "/login"; // Redirigir al login después del logout
  };

  if (!isAuthenticated) {
    return null; // No renderizar nada si no está autenticado
  }

  return (
    <div className="admin-index">
      <header className="d-flex justify-content-between align-items-center">
        <img src={logo} alt="VENS Logo" width={150} className="mb-4" />
        <Button variant="danger" onClick={handleLogout}>
          {t("logout")}
        </Button>
      </header>
      <div className="summary">
        <div className="card">
          <h3>{t("users")}</h3>
          <p>0</p>
          <p className="text-danger">-1.15% {t("last_week")}</p>
        </div>
        <div className="card">
          <h3>{t("appointments")}</h3>
          <p>4500</p>
          <p className="text-success">+9.65% {t("last_week")}</p>
        </div>
        <div className="card">
          <h3>{t("monthly_revenue")}</h3>
          <p>$12,029,500</p>
          <p className="text-success">+4.5% {t("last_week")}</p>
        </div>
      </div>
      <div className="clients">
        <h3>{t("clients")}</h3>
        <Button variant="primary" onClick={() => handleOpenModal()}>
          {t("add_client")}
        </Button>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>{t("active")}</th>
                <th>{t("name_or_business")}</th>
                <th>{t("email")}</th>
                <th>{t("actions")}</th>
              </tr>
            </thead>
            <tbody>
              {/* Ejemplo de un cliente en la tabla */}
              <tr>
                <td>{t("active")}</td>
                <td>Nombre de Ejemplo</td>
                <td>ejemplo@correo.com</td>
                <td>
                  <Button
                    variant="link"
                    onClick={() =>
                      handleOpenModal({
                        name: "Nombre de Ejemplo",
                        email: "ejemplo@correo.com",
                        phone: "123456789",
                        password: "123456",
                        isTransbankClient: false,
                        transbankAccount: "",
                        transbankTerminal: "",
                      })
                    }
                  >
                    {t("edit")}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("add_client")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddClient clientData={currentClient} onSave={handleSaveClient} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminIndex;
