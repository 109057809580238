import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarView.scss";
import { useTranslation } from "react-i18next";

const localizer = momentLocalizer(moment);

const generatePastelColor = () => {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, 100%, 85%)`;
};

const BigCalendarView = ({ appointments, onBlockHour, associateId }) => {
  const { t } = useTranslation();

  const events = appointments.map((appointment) => {
    const startTime = new Date(
      `${appointment.date}T${appointment.time.split("-")[0]}`
    );
    const endTime = new Date(
      `${appointment.date}T${appointment.time.split("-")[1]}`
    );
    return {
      title: appointment.name || t("available"),
      start: startTime,
      end: endTime,
      allDay: false,
      backgroundColor: appointment.name ? generatePastelColor() : "lightgreen",
      associateId: appointment.associateId,
    };
  });

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "black",
      border: "1px solid",
      display: "block",
    };
    return {
      style: style,
    };
  };

  const handleSelectSlot = ({ start, end }) => {
    const startHour = moment(start).format("HH:mm");
    const endHour = moment(end).format("HH:mm");
    if (onBlockHour) {
      onBlockHour({ startHour, endHour });
    }
  };

  return (
    <div className="big-calendar-view">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={["month", "week", "day"]}
        defaultView="week"
        eventPropGetter={eventStyleGetter}
        selectable
        onSelectSlot={handleSelectSlot}
      />
    </div>
  );
};

export default BigCalendarView;
