// src/components/Common/Header.js
import React from "react";
import { useSelector } from "react-redux";

import logo from "../../../assets/img/logo-vens.png";
import "./Header.scss";

const Header = () => {
  const { loading, error, isAuthenticated, user } = useSelector(
    (state) => state.auth
  );
  return (
    <header className="header">
      <img src={user.logo} alt="VENS Logo" className="header-logo" />
    </header>
  );
};

export default Header;
