import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfessionalForm from "./professionalForm/professionalForm";
import ServiceAssignmentForm from "../Professional/addServices/ServiceAssignmentForm";
// import AvailabilityForm from "./availabilityForm/availabilityForm";
import { getProfessionalsByCompanyId } from "../../../../store/professionalSlice";
import { Button, Table } from "react-bootstrap";
import {
  FaEdit,
  FaTrash,
  FaClipboardList,
  FaCalendarPlus,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ProfessionalManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [professionals, setProfessionals] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getProfessionalsByComId = async () => {
      const { payload } = await dispatch(getProfessionalsByCompanyId(user._id));
      setProfessionals(payload);
    };

    getProfessionalsByComId();
  }, [dispatch, user._id, refresh]);

  const openModal = (professional = null) => {
    setSelectedProfessional(professional);
    setIsModalOpen(true);
  };

  const openServiceModal = (professional) => {
    setSelectedProfessional(professional);
    setIsServiceModalOpen(true);
  };

  const openAvailabilityModal = () => {
    // Redirigir a la URL específica
    navigate("/dashboard/schedule");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProfessional(null);
    setRefresh(!refresh);
  };

  const closeServiceModal = () => {
    setIsServiceModalOpen(false);
    setSelectedProfessional(null);
    setRefresh(!refresh);
  };

  const closeAvailabilityModal = () => {
    setIsAvailabilityModalOpen(false);
    setSelectedProfessional(null);
    setRefresh(!refresh);
  };

  const handleDelete = (id) => {
    if (window.confirm(t("are_you_sure"))) {
      // dispatch(deleteProfessional(id));
    }
  };

  const handleSave = () => {
    console.log("Professional saved");
  };

  const handleServiceSave = () => {
    console.log("Services assigned");
  };

  const handleAvailabilitySave = () => {
    console.log("Availability created");
  };

  const renderServiceButtons = (professional) => {
    if (user.services.length === professional.services.length) {
      return (
        <Button
          variant="danger"
          onClick={() => handleDelete(professional._id)}
          className="me-2"
        >
          <FaTrash /> {t("delete_service")}
        </Button>
      );
    } else if (user.services.length < professional.services.length) {
      return (
        <>
          <Button
            variant="danger"
            onClick={() => handleDelete(professional._id)}
            className="me-2"
          >
            <FaTrash /> {t("delete_service")}
          </Button>
          <Button
            variant="info"
            onClick={() => openServiceModal(professional)}
            className="me-2"
          >
            <FaClipboardList /> {t("assign_service")}
          </Button>
        </>
      );
    } else {
      return (
        <Button
          variant="info"
          onClick={() => openServiceModal(professional)}
          className="me-2"
        >
          <FaClipboardList /> {t("assign_service")}
        </Button>
      );
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={() => openModal()} className="mb-3">
        {t("create_professional")}
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t("name")}</th>
            <th>{t("rol")}</th>
            <th>{t("email")}</th>
            <th>{t("phone")}</th>
            <th>{t("services")}</th>
            <th>{t("manage_services")}</th>
            <th>{t("manage_availability")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        {professionals && (
          <tbody>
            {professionals.map((professional) => (
              <tr key={professional._id}>
                <td>{professional.name}</td>
                <td>{professional.rol}</td>
                <td>{professional.email}</td>
                <td>{professional.phone}</td>
                <td>
                  {professional.services.map((service) => (
                    <span key={service._id}>* {service.name} </span>
                  ))}
                </td>
                <td>{renderServiceButtons(professional)}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => openAvailabilityModal(professional)}
                  >
                    <FaCalendarPlus /> {t("create_availability")}
                  </Button>
                </td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => openModal(professional)}
                    className="me-2"
                  >
                    <FaEdit />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(professional._id)}
                  >
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {isModalOpen && (
        <ProfessionalForm
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          professional={selectedProfessional}
          isEdit={!!selectedProfessional}
          onSave={handleSave}
        />
      )}
      {isServiceModalOpen && (
        <ServiceAssignmentForm
          isModalOpen={isServiceModalOpen}
          closeModal={closeServiceModal}
          professional={selectedProfessional}
          onSave={handleServiceSave}
        />
      )}
      {/* {isAvailabilityModalOpen && (
        <AvailabilityForm
          isModalOpen={isAvailabilityModalOpen}
          closeModal={closeAvailabilityModal}
          professional={selectedProfessional}
          onSave={handleAvailabilitySave}
        />
      )} */}
    </div>
  );
};

export default ProfessionalManagement;
