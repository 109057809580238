import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Register.scss';
import logo from '../../../assets/img/logo-vens-white.png';

const Register = ({ onNext }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!email || !password) {
      setError(t('error_fill_all_fields'));
      return;
    }

    if (!validateEmail(email)) {
      setError(t('error_invalid_email'));
      return;
    }

    onNext({ email, password });
    setSuccess(t('registration_success'));
  };
  
    const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  

  return (
    <div className="onboarding-register">
      <div className="sidebar">
        <div className="logo-container">
          <img src={logo} alt="VENS Logo" width={200} className="logo" />
        </div>
        <div className="welcome-message">
         <h2>{t('welcome_title')}</h2>
           <p>{t('welcome_message')}</p>
        </div>
      </div>
      <div className="content">
        <div className="form-container">
          <h2 className="form-title">{t('complete_registration')}</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          {success && <div className="alert alert-success">{success}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">{t('email')}</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder={t('email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">{t('temporary_password')}</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder={t('temporary_password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100 mt-3">{t('continue')}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
