import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { login } from "../../../../store/authSlice";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import logo from "../../../../assets/img/logo-vens.png";
import logo2 from "../../../../assets/img/logo-venture.png";

const ClientLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (error) {
      setSuccess("");
    }
    if (isAuthenticated) {
      setSuccess(t("login_success"));
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } else {
      navigate("/login");
    }
  }, [error, isAuthenticated, navigate, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess("");
    const resultAction = await dispatch(login({ email, password }));
    if (login.fulfilled.match(resultAction)) {
      setSuccess(t("login_success"));
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } else if (login.rejected.match(resultAction)) {
      setSuccess("");
    }
  };

  const getErrorMessage = (error) => {
    if (typeof error === "string") {
      return error;
    } else if (error?.message) {
      return error.message;
    } else if (error?.statusCode) {
      return `Error ${error.statusCode}: ${error.message}`;
    }
    return t("unknown_error");
  };

  return (
    <div className="client-login-container">
      <div className="client-login-header">
        <img src={logo} alt="VENS Logo" className="client-login-logo" />
      </div>
      <div className="client-login-content">
        <div className="client-login-image">
          <img src={logo2} alt="VENS Logo" className="client-login-logo" />
        </div>
        <div className="client-login-form">
          <h2>{t("login_title")}</h2>
          {error && (
            <div className="alert alert-danger">{getErrorMessage(error)}</div>
          )}
          {success && <div className="alert alert-success">{success}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">{t("email")}</label>
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder={t("email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">{t("password")}</label>
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder={t("password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? t("loading") : t("login_button")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <footer className="client-login-footer">
        <p>{t("developed_by")}</p>
      </footer>
    </div>
  );
};

export default ClientLogin;
