// src/utils/dataStorage.js

export const getWorkingHours = (associateId) => {
  const associates = getAllAssociates();
  const associate = associates.find((a) => a.id === associateId);
  return associate ? associate.availability : {};
};

export const getServices = () => {
  const associates = getAllAssociates();
  const services = [];

  associates.forEach((associate) => {
    associate.services.forEach((service) => {
      if (!services.some((s) => s.name === service.name)) {
        services.push(service);
      }
    });
  });

  return services;
};

export const getAllServices = () => {
  const associates = getAllAssociates();
  let allServices = [];

  associates.forEach((resp) => {
    if (resp.services) {
      const servicesWithName = resp.services.map((service) => ({
        ...service,
        associateName: resp.name,
      }));
      allServices = allServices.concat(servicesWithName);
    }
  });

  return allServices;
};

export const findServicesById = (serviceId) => {
  const serviceIdInt = parseInt(serviceId, 10);

  const associates = getAllAssociates();
  let relatedServices = [];
  let selectedServiceName = "";

  associates.forEach((associate) => {
    const foundService = associate.services.find(
      (service) => service.id === serviceIdInt
    );
    if (foundService) {
      selectedServiceName = foundService.name;
    }
  });
  if (selectedServiceName) {
    associates.forEach((associate) => {
      const services = associate.services.filter(
        (service) => service.name === selectedServiceName
      );
      relatedServices = [...relatedServices, ...services];
    });
  }

  return relatedServices;
};

export const getAppointments = () => {
  return [
    {
      client: "Elsie Gilley",
      email: "correo@esteeselcorreo.com",
      phone: "+56 9 9999 9999",
      date: "9 Jul 2024 08:00",
      serviceId: 2,
      serviceName: "Peluquería",
      associateId: 1,
    },
    {
      client: "John Doe",
      email: "john@example.com",
      phone: "+56 9 9999 8888",
      date: "9 Jul 2024 10:00",
      serviceId: 1,
      serviceName: "Peluquería",
      associateId: 2,
    },
    {
      client: "Elsie Gilley",
      email: "correo@esteeselcorreo.com",
      phone: "+56 9 9999 9999",
      date: "10 Jul 2024 08:00",
      serviceId: 3,
      serviceName: "Peluquería",
      associateId: 1,
    },
    {
      client: "John Doe",
      email: "john@example.com",
      phone: "+56 9 9999 8888",
      date: "26 Jun 2024 10:00",
      service: "Servicio 1",
      serviceId: 4,
      serviceName: "Peluquería",
      associateId: 2,
    },
    {
      client: "John Doe",
      email: "john@example.com",
      phone: "+56 9 9999 8888",
      date: "4 Jul 2024 10:00",
      service: "Servicio 1",
      serviceId: 4,
      serviceName: "Peluquería",
      associateId: 2,
    },
    {
      client: "John Doe",
      email: "john@example.com",
      phone: "+56 9 9999 8888",
      date: "5 Jul 2024 10:00",
      service: "Servicio 1",
      serviceId: 4,
      serviceName: "Peluquería",
      associateId: 2,
    },
    {
      client: "John Doe",
      email: "john@example.com",
      phone: "+56 9 9999 8888",
      date: "6 Jul 2024 10:00",
      service: "Servicio 1",
      serviceId: 4,
      serviceName: "Peluquería",
      associateId: 2,
    },
    // Agrega más citas aquí según sea necesario
  ];
};

export const getAllAssociates = () => {
  return [
    {
      id: 1,
      name: "Associate 1",
      Rol: "Administrador",
      availability: {
        monday: ["06:00", "08:00", "10:00", "12:00", "14:00"],
        tuesday: ["06:00", "08:00", "10:00", "12:00", "13:00", "16:00"],
        wednesday: [
          "06:00",
          "08:00",
          "10:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
        ],
        thursday: ["06:00", "08:00", "10:00", "12:00"],
        friday: ["11:00", "13:00", "15:00", "17:00"],
      },
      services: [
        {
          id: 0,
          feed: 10,
          rate: 6,
          idAssociate: 1,
          name: "Peluquería",
          place: "Santiago, Chile",
          duration: 30,
          price: 20000,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 1",
          aditionalImg: [
            "https://via.placeholder.com/400x200",
            "https://via.placeholder.com/400x200",
          ],
        },
        {
          id: 1,
          feed: 10,
          rate: 5,
          idAssociate: 1,
          place: "Santiago, Chile",
          price: 17000,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Manicure + Pedicure",
          duration: 60,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 2",
        },
        {
          id: 2,
          feed: 13,
          place: "Santiago, Chile",
          rate: 4,
          idAssociate: 1,
          price: 174000,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Manicure",
          duration: 45,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 3",
        },
        {
          id: 3,
          rate: 3,
          feed: 10,
          place: "Santiago, Chile",
          price: 10000,
          idAssociate: 1,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Manicure + Pedicure",
          duration: 45,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 3",
        },
        {
          id: 4,
          feed: 10,
          rate: 2,
          idAssociate: 1,
          place: "Santiago, Chile",
          price: 23000,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Depilación + Pedicure",
          duration: 45,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 3",
        },
      ],
    },
    {
      id: 2,
      name: "Associate 2",
      Rol: "Manicurista",
      idAdmin: 1,
      availability: {
        monday: ["06:00", "08:00", "10:00", "12:00", "19:00"],
        tuesday: ["06:00", "08:00", "10:00", "12:00", "13:00", "16:00"],
        wednesday: ["06:00", "08:00", "10:00", "12:00", "20:00"],
        thursday: ["06:00", "08:00", "10:00", "12:00"],
        friday: ["11:00", "13:00", "15:00", "17:00"],
      },
      services: [
        {
          id: 5,
          rate: 6,
          idAssociate: 2,
          place: "Santiago, Chile",
          name: "Peluquería",
          duration: 30,
          price: 20000,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 1",
        },
        {
          id: 6,
          name: "Manicure + Pedicure",
          duration: 30,
          rate: 5,
          idAssociate: 2,
          place: "Santiago, Chile",
          price: 30000,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 1",
        },
        {
          id: 7,
          price: 17000,
          idAssociate: 2,
          place: "Santiago, Chile",
          rate: 6,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Manicure",
          duration: 60,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 2",
        },
        {
          id: 8,
          price: 174000,
          idAssociate: 2,
          place: "Santiago, Chile",
          rate: 5,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Pedicure",
          duration: 45,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 3",
        },
        {
          id: 9,
          rate: 4,
          price: 10000,
          idAssociate: 2,
          place: "Santiago, Chile",
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Depilación",
          duration: 45,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 4",
        },
        {
          id: 10,
          rate: 3,
          idAssociate: 2,
          place: "Santiago, Chile",
          price: 23000,
          description:
            "esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa, esta es una descripcion larga la idea es ver como se comportaaaa",
          name: "Depilación + Pedicure",
          duration: 45,
          imageUrl: "https://via.placeholder.com/400x200",
          descrition: "Descripción del servicio 5",
        },
      ],
    },
  ];
};
export const getServicesByAssociateId = (associateId) => {
  const associates = getAllAssociates();
  let servicesByAssociate = [];

  associates.forEach((resp) => {
    if (resp.id === associateId && resp.services) {
      const servicesWithName = resp.services.map((service) => ({
        ...service,
        associateName: resp.name,
      }));
      servicesByAssociate = servicesByAssociate.concat(servicesWithName);
    }
  });

  return servicesByAssociate;
};

/// CAMBIAR LUEGO DAYAN

let blockedHours = []; // Array para almacenar los horarios bloqueados

export const getBlockedHours = () => {
  return blockedHours;
};

export const addBlockedHour = (blockedHour) => {
  const existingBlock = blockedHours.find(
    (block) =>
      block.associateId === blockedHour.associateId &&
      block.date === blockedHour.date
  );
  if (existingBlock) {
    existingBlock.hours.push(...blockedHour.hours);
  } else {
    blockedHours.push(blockedHour);
  }
};

export const removeBlockedHour = (associateId, date) => {
  blockedHours = blockedHours.filter(
    (hour) => !(hour.associateId === associateId && hour.date === date)
  );
};

// src/utils/dataStorage.js

let clientData = {
  name: "Elsie Gilley",
  email: "correo@esteeselcorreo.com",
  phone: "+56 9 9999 9999",
  address: "123 Main Street, Santiago, Chile",
};

export const getClientData = () => {
  return clientData;
};

export const updateClientData = (newData) => {
  clientData = { ...clientData, ...newData };
};

// src/utils/dataStorage.js

export const getServicesClient = () => {
  return [
    {
      id: 1,
      name: "Peluquería",
      duration: 90,
      price: 20000,
      deposit: 5000,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Porttitor massa id neque aliquam.",
      imageUrl: "https://via.placeholder.com/400x600",
    },
    {
      id: 2,
      name: "Manicure",
      duration: 60,
      price: 17000,
      deposit: 4000,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Porttitor massa id neque aliquam.",
      imageUrl: "https://via.placeholder.com/400x600",
    },
    {
      id: 3,
      name: "Manicure + Pedicure",
      duration: 150,
      price: 25000,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Porttitor massa id neque aliquam. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      deposit: 0, // Sin abono solicitado
      imageUrl: "https://via.placeholder.com/400x600",
    },
    {
      id: 4,
      name: "Depilación",
      duration: 30,
      price: 10000,
      deposit: 3000,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Porttitor massa id neque aliquam.",
      imageUrl: "https://via.placeholder.com/400x600",
    },
    {
      id: 5,
      name: "Depilación + Manicure",
      duration: 120,
      price: 23000,
      deposit: 5000,
      description: "Descripción del servicio 1",
      imageUrl: "https://via.placeholder.com/400x600",
    },
    {
      id: 6,
      name: "Depilación + Pedicure",
      duration: 120,
      price: 23000,
      deposit: 5000,
      description: "Descripción del servicio 1",
      imageUrl: "https://via.placeholder.com/400x600",
    },
    {
      id: 7,
      name: "Depilación + Manicure + Pedicure",
      duration: 180,
      price: 30000,
      deposit: 7000,
      description: "Descripción del servicio 1",
      imageUrl: "https://via.placeholder.com/400x600",
    },
  ];
};
