import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Services.scss';

const Services = ({ data, onNext, existingServices }) => {
  const { t } = useTranslation();
  const [services, setServices] = useState(existingServices || []);
  const [newService, setNewService] = useState({ name: '', duration: '', price: '', deposit: '', description: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (existingServices) {
      setServices(existingServices);
    }
  }, [existingServices]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'price' || name === 'deposit') {
      formattedValue = value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    setNewService((prevService) => ({ ...prevService, [name]: formattedValue }));
  };

  const handleAddService = (e) => {
    e.preventDefault();
    if (editingIndex !== null) {
      setServices((prevServices) =>
        prevServices.map((service, index) => index === editingIndex ? newService : service)
      );
    } else {
      setServices((prevServices) => [...prevServices, newService]);
    }
    setNewService({ name: '', duration: '', price: '', deposit: '', description: '' });
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleEditService = (index) => {
    setNewService(services[index]);
    setIsEditing(true);
    setEditingIndex(index);
  };

  const handleRemoveService = (index) => {
    setServices((prevServices) => prevServices.filter((_, i) => i !== index));
  };

  const handleContinue = () => {
    onNext(services);
  };

  return (
    <div className="services">
      <h2>{t('services')}</h2>
      <p>{t('services_description')}</p>
      <div className="service-list">
        {services.map((service, index) => (
          <div key={index} className="service-item">
            <h4>{service.name}</h4>
            <p>{t('duration')}: {service.duration}</p>
            <p>{t('price')}: ${service.price}</p>
            <p>{t('deposit')}: ${service.deposit}</p>
            <p>{t('description')}: {service.description}</p>
            <button className="edit" onClick={() => handleEditService(index)}>{t('edit')}</button>
            <button className="remove" onClick={() => handleRemoveService(index)}>{t('remove')}</button>
          </div>
        ))}
      </div>
      {isEditing ? (
        <form onSubmit={handleAddService} className="service-form">
          <input
            type="text"
            name="name"
            placeholder={t('service_name')}
            value={newService.name}
            onChange={handleInputChange}
            required
          />
          <select
            name="duration"
            value={newService.duration}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>{t('select_duration')}</option>
            <option value="10 min">10 min</option>
            <option value="30 min">30 min</option>
            <option value="45 min">45 min</option>
            <option value="1 hour">1 hour</option>
            <option value="1 hour 30 min">1 hour 30 min</option>
            <option value="2 hours">2 hours</option>
          </select>
          <input
            type="text"
            name="price"
            placeholder={t('price')}
            value={newService.price}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="deposit"
            placeholder={t('deposit')}
            value={newService.deposit}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="description"
            placeholder={t('description')}
            value={newService.description}
            onChange={handleInputChange}
            required
          />
          <button type="submit" className="btn btn-primary">{t('save_service')}</button>
        </form>
      ) : (
        <button className="add" onClick={() => setIsEditing(true)}>{t('add_service')}</button>
      )}
      <button className="btn btn-primary w-100 mt-3" onClick={handleContinue} disabled={services.length === 0}>
        {t('continue')}
      </button>
    </div>
  );
};

export default Services;
