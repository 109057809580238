import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaHome,
  FaCalendarAlt,
  FaClock,
  FaUser,
  FaTools,
  FaMoneyCheckAlt,
  FaLock,
  FaSignOutAlt,
} from "react-icons/fa";
import "./Sidebar.scss";

const Sidebar = ({
  currentView,
  setCurrentView,
  toggleSidebar,
  isExpanded,
}) => {
  const { t } = useTranslation();

  const handleItemClick = (view) => {
    setCurrentView(view);
  };

  return (
    <aside className={`sidebar ${isExpanded ? "expanded" : "collapsed"}`}>
      <button className="toggle-btn" onClick={toggleSidebar}>
        ☰
      </button>

      <ul className="sidebar-menu">
        <li
          className={`menu-item ${currentView === "home" ? "active" : ""}`}
          onClick={() => handleItemClick("home")}
        >
          <FaHome /> {isExpanded && t("home")}
        </li>
        <li
          className={`menu-item ${
            currentView === "appointments" ? "active" : ""
          }`}
          onClick={() => handleItemClick("appointments")}
        >
          <FaCalendarAlt /> {isExpanded && t("appointments")}
        </li>
        <li
          className={`menu-item ${currentView === "schedule" ? "active" : ""}`}
          onClick={() => handleItemClick("schedule")}
        >
          <FaClock /> {isExpanded && t("schedule")}
        </li>
        <li
          className={`menu-item ${
            currentView === "time_blocks" ? "active" : ""
          }`}
          onClick={() => handleItemClick("time_blocks")}
        >
          <FaClock /> {isExpanded && t("time_blocks")}
        </li>
        <li
          className={`menu-item ${currentView === "profile" ? "active" : ""}`}
          onClick={() => handleItemClick("profile")}
        >
          <FaUser /> {isExpanded && t("profile")}
        </li>
        <li
          className={`menu-item ${currentView === "services" ? "active" : ""}`}
          onClick={() => handleItemClick("services")}
        >
          <FaTools /> {isExpanded && t("services")}
        </li>
        <li
          className={`menu-item ${
            currentView === "professional" ? "active" : ""
          }`}
          onClick={() => handleItemClick("professional")}
        >
          <FaUser /> {isExpanded && t("professional")}
        </li>
        <li
          className={`menu-item ${
            currentView === "payment_methods" ? "active" : ""
          }`}
          onClick={() => handleItemClick("payment_methods")}
        >
          <FaMoneyCheckAlt /> {isExpanded && t("payment_methods")}
        </li>
        <li
          className={`menu-item ${currentView === "HomePage" ? "active" : ""}`}
          onClick={() => handleItemClick("HomePage")}
        >
          <FaHome /> {isExpanded && t("HomePage")}
        </li>
        {/* <li
          className={`menu-item ${
            currentView === "change_password" ? "active" : ""
          }`}
          onClick={() => handleItemClick("change_password")}
        >
          <FaLock /> {isExpanded && t("change_password")}
        </li> */}
        {/* <li
          className={`menu-item ${currentView === "HomePage" ? "active" : ""}`}
          onClick={() => handleItemClick("HomePage")}
        >
          <FaHome /> {isExpanded && t("HomePage")}
        </li> */}
        <li className="menu-item" onClick={() => handleItemClick("logout")}>
          <FaSignOutAlt /> {isExpanded && t("logout")}
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
