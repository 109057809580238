import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AddClient.scss';

const AddClient = ({ clientData, onSave }) => {
  const { t } = useTranslation();

  // Estados para los valores de los inputs
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [isTransbankClient, setIsTransbankClient] = useState(false);
  const [transbankAccount, setTransbankAccount] = useState('');
  const [transbankTerminal, setTransbankTerminal] = useState('');

  // Efecto para inicializar los valores en modo de edición
  useEffect(() => {
    if (clientData) {
      setName(clientData.name || '');
      setEmail(clientData.email || '');
      setPhone(clientData.phone || '');
      setPassword(clientData.password || '');
      setIsTransbankClient(clientData.isTransbankClient || false);
      setTransbankAccount(clientData.transbankAccount || '');
      setTransbankTerminal(clientData.transbankTerminal || '');
    }
  }, [clientData]);

  // Manejador del envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    const clientInfo = {
      name,
      email,
      phone,
      password,
      isTransbankClient,
      transbankAccount,
      transbankTerminal
    };
    onSave(clientInfo);
  };

  return (
    <div className="add-client">
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="name">{t('name_or_business')}</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder={t('name_or_business')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder={t('email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="phone">{t('phone')}</label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            placeholder={t('phone')}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="password">{t('password')}</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder={t('password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label>{t('client_status')}</label>
          <div className="custom-radio">
            <input
              type="radio"
              id="active"
              name="status"
              value="active"
              checked={!isTransbankClient}
              onChange={() => setIsTransbankClient(false)}
            />
            <label htmlFor="active">{t('active')}</label>
          </div>
          <div className="custom-radio">
            <input
              type="radio"
              id="transbank"
              name="status"
              value="transbank"
              checked={isTransbankClient}
              onChange={() => setIsTransbankClient(true)}
            />
            <label htmlFor="transbank">{t('transbank_client')}</label>
          </div>
        </div>
        {isTransbankClient && (
          <div className="transbank-fields">
            <div className="form-group mb-3">
              <label htmlFor="transbankAccount">{t('transbank_account')}</label>
              <input
                type="text"
                className="form-control"
                id="transbankAccount"
                placeholder={t('transbank_account')}
                value={transbankAccount}
                onChange={(e) => setTransbankAccount(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="transbankTerminal">{t('transbank_terminal')}</label>
              <input
                type="text"
                className="form-control"
                id="transbankTerminal"
                placeholder={t('transbank_terminal')}
                value={transbankTerminal}
                onChange={(e) => setTransbankTerminal(e.target.value)}
                required
              />
            </div>
          </div>
        )}
        <button type="submit" className="btn btn-primary w-100">{t('save')}</button>
      </form>
    </div>
  );
};

export default AddClient;
