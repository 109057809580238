// import React from "react";
// import "./Map.scss";

// const Map = ({ companyData }) => {
//   const generateMapUrl = (address) => {
//     const encodedAddress = encodeURIComponent(address);
//     //https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.510992456692!2d-70.6252578!3d-33.4620446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c560f2976a17%3A0x2133be5eb4bd8ed0!2sSan%20Eugenio%201065%2C%20%C3%91u%C3%B1oa%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1671033614923!5m2!1sen!2scl
//     return `https://www.google.com/maps/embed/v1/place?q=${encodedAddress}&key=YOUR_API_KEY`;
//   };

//   return (
//     <section className="map-section">
//       <div className="container">
//         <div className="row">
//           <div className="row">
//             <div className="col-12 text-center">
//               <h2>{companyData.userText.mapTitle}</h2>
//             </div>
//           </div>
//           <div className="col-12">
//             <div className="map-box">
//               <iframe
//                 src={generateMapUrl(companyData.address)}
//                 width="600"
//                 height="400"
//                 style={{ border: 0 }}
//                 allowFullScreen=""
//                 loading="lazy"
//                 referrerPolicy="no-referrer-when-downgrade"
//                 title="location-map"
//               ></iframe>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Map;

import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./Map.scss";

// Icon configuration for Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const customMarkerIcon = new L.Icon({
  iconUrl: require("../../../assets/img/marker-icon2.png"), // Asegúrate de tener tu propio ícono de marcador en esta ruta
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  shadowSize: [41, 41],
});

const Map = ({ companyData }) => {
  const { address } = companyData;
  const [position, setPosition] = useState(null);

  useEffect(() => {
    console.log("address", address);
    const getCoordinates = async (address) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
            address
          )}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const text = await response.text();
        const data = JSON.parse(text); // Parse the response as JSON
        if (data.length > 0) {
          setPosition([data[0].lat, data[0].lon]);
        } else {
          console.log("No data found for the provided address.");
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };

    getCoordinates(address);
  }, [address]);

  return (
    <section className="map-section">
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-12 text-center">
              <h2>{companyData.userText.mapTitle}</h2>
            </div>
          </div>
          <div className="col-12">
            <div className="map-box">
              {position ? (
                <MapContainer
                  center={position}
                  zoom={13}
                  style={{ height: "400px", width: "100%" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={position} icon={customMarkerIcon}>
                    <Popup>{address}</Popup>
                  </Marker>
                </MapContainer>
              ) : (
                <p>Loading map...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Map;
