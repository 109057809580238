// HomePage.js
import React, { useState, useEffect } from "react";
import ConfigEditor from "../ClientDashboard/ConfigEditor/ConfigEditor";
import IndexHeader from "../../User/IndexHeader/IndexHeader";
import Banner from "../../User/Banner/Banner";
import Services from "../../User/Services/Services";
import Map from "../../User/Map/Map";
import IndexFooter from "../../User/IndexFooter/IndexFooter";
import "./HomePage.scss";
import axiosInstance from "../../../auth/axiosConfig";

const HomePage = () => {
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPreviewFull, setIsPreviewFull] = useState(false);

  const togglePreviewSize = () => {
    setIsPreviewFull(!isPreviewFull);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/company/668aea480f246da25927bb30`
        );
        console.log("response", response.data);
        setCompanyData(response.data);
        setLoading(false);
        console.log("companyData", response.data);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const saveCompanyData = async (data) => {
    console.log("data", data);
    try {
      //  const response = await axiosInstance.put(
      //    `/company/668aea480f246da25927bb30`,
      //    data
      //  );
      //  console.log("response", response.data);
      //  setCompanyData(response.data);
      //  setLoading(false);
      //  console.log("companyData", response.data);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  return (
    <>
      {companyData && (
        <div className="home-page">
          <div className="config-section">
            <ConfigEditor
              companyData={companyData}
              setCompanyData={setCompanyData}
              sentData={saveCompanyData}
            />
          </div>
          <div className={`preview-section ${isPreviewFull ? "full" : ""}`}>
            <button onClick={togglePreviewSize} className="preview-toggle-btn">
              {isPreviewFull ? "Minimize Preview" : "Maximize Preview"}
            </button>
            <div className="mt-5">
              <IndexHeader companyData={companyData} />
              <Banner companyData={companyData} />
              <Services companyData={companyData} />
              <Map companyData={companyData} />
              <IndexFooter companyData={companyData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
