import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { servicesAPI } from "../services/api";

// Asynchronous thunk actions
export const fetchServices = createAsyncThunk(
  "services/fetchServices",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await servicesAPI.getServicesByCompanyId(companyId);
      console.log("response estoy en el slice", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

export const createService = createAsyncThunk(
  "services/createService",
  async (serviceData, { rejectWithValue }) => {
    try {
      const response = await servicesAPI.createService(serviceData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Unknown error occurred"
      );
    }
  }
);

const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.loading = false;
        state.services.push(action.payload);
      })
      .addCase(createService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = servicesSlice.actions;

export default servicesSlice.reducer;
