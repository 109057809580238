import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './PaymentMethods.scss';

const PaymentMethods = ({ data, onNext }) => {
  const { t } = useTranslation();
  const [paymentMethods, setPaymentMethods] = useState({
    cash: false,
    transfer: false,
    card: false,
  });

  const handleToggle = (method) => {
    setPaymentMethods((prevMethods) => ({
      ...prevMethods,
      [method]: !prevMethods[method],
    }));
  };

  const handleContinue = () => {
    onNext(paymentMethods);
  };

  return (
    <div className="payment-methods">
      <h2>{t('payment_methods')}</h2>
      <p>{t('select_payment_methods')}</p>
      <div className="method-list">
        <div className="method-item">
          <label className="switch">
            <input type="checkbox" checked={paymentMethods.cash} onChange={() => handleToggle('cash')} />
            <span className="slider round"></span>
          </label>
          <span>{t('cash')}</span>
        </div>
        <div className="method-item">
          <label className="switch">
            <input type="checkbox" checked={paymentMethods.transfer} onChange={() => handleToggle('transfer')} />
            <span className="slider round"></span>
          </label>
          <span>{t('transfer')}</span>
        </div>
        <div className="method-item">
          <label className="switch">
            <input type="checkbox" checked={paymentMethods.card} onChange={() => handleToggle('card')} />
            <span className="slider round"></span>
          </label>
          <span>{t('card')}</span>
        </div>
      </div>
      <button className="btn btn-primary w-100 mt-3" onClick={handleContinue}>{t('continue')}</button>
    </div>
  );
};

export default PaymentMethods;
