import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllAssociates, getBlockedHours, addBlockedHour, removeBlockedHour } from '../../../../Utils/dateAvailable/dataStorage';
import BigCalendarView from './../Appointments/CalendarView/CalendarView';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import './TimeBlocks.scss';




const BlockHours = () => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedAssociate, setSelectedAssociate] = useState('');
  const [blockedHours, setBlockedHours] = useState([]);
  const [blockType, setBlockType] = useState('hours');
  const associates = getAllAssociates();

  useEffect(() => {
    setBlockedHours(getBlockedHours());
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAssociateChange = (e) => {
    setSelectedAssociate(e.target.value);
  };

  const handleBlockHour = (hour) => {
    if (!selectedAssociate) {
      toast.error(t('select_associate_first'));
      return;
    }

    const date = selectedDate.toISOString().split('T')[0];
    const newBlockedHour = {
      associateId: parseInt(selectedAssociate),
      date: date,
      hours: [hour]
    };
    addBlockedHour(newBlockedHour);
    setBlockedHours(getBlockedHours());
    toast.success(t('hour_blocked'));
  };

  const handleBlockDay = () => {
    if (!selectedAssociate) {
      toast.error(t('select_associate_first'));
      return;
    }

    const date = selectedDate.toISOString().split('T')[0];
    const associate = associates.find(a => a.id === parseInt(selectedAssociate));
    if (!associate) return;

    const availableHours = associate.availability[selectedDate.toLocaleDateString('en-us', { weekday: 'long' }).toLowerCase()] || [];
    const newBlockedHour = {
      associateId: parseInt(selectedAssociate),
      date: date,
      hours: availableHours
    };
    addBlockedHour(newBlockedHour);
    setBlockedHours(getBlockedHours());
    toast.success(t('day_blocked'));
  };

  const handleBlockHalfDay = () => {
    if (!selectedAssociate) {
      toast.error(t('select_associate_first'));
      return;
    }

    const date = selectedDate.toISOString().split('T')[0];
    const associate = associates.find(a => a.id === parseInt(selectedAssociate));
    if (!associate) return;

    const availableHours = associate.availability[selectedDate.toLocaleDateString('en-us', { weekday: 'long' }).toLowerCase()] || [];
    const halfDayHours = availableHours.slice(0, Math.floor(availableHours.length / 2));
    const newBlockedHour = {
      associateId: parseInt(selectedAssociate),
      date: date,
      hours: halfDayHours
    };
    addBlockedHour(newBlockedHour);
    setBlockedHours(getBlockedHours());
    toast.success(t('half_day_blocked'));
  };

  const handleUnblockDay = () => {
    if (!selectedAssociate) {
      toast.error(t('select_associate_first'));
      return;
    }

    const date = selectedDate.toISOString().split('T')[0];
    removeBlockedHour(parseInt(selectedAssociate), date);
    setBlockedHours(getBlockedHours());
    toast.success(t('day_unblocked'));
  };

  const events = blockedHours.map((blocked) => ({
    title: t('blocked'),
    start: new Date(`${blocked.date}T${blocked.hours[0]}:00`),
    end: new Date(`${blocked.date}T${blocked.hours[blocked.hours.length - 1]}:00`),
    allDay: false,
    associateId: blocked.associateId
  }));

  return (
    <div className="block-hours">
      <ToastContainer />
      <h3>{t('block_hours')}</h3>
      <p>{t('block_hours_description')}</p>
      <div className="filters">
        <div className="filter">
          <label>{t('date')}</label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd-MM-yyyy"
          />
        </div>
        <div className="filter">
          <label>{t('associate')}</label>
          <select value={selectedAssociate} onChange={handleAssociateChange}>
            <option value="">{t('select_associate')}</option>
            {associates.map((associate) => (
              <option key={associate.id} value={associate.id}>
                {associate.name}
              </option>
            ))}
          </select>
        </div>
        
        <div className="filter">
          <label>{t('block_type')}</label>
          <select value={blockType} onChange={(e) => setBlockType(e.target.value)}>
            <option value="hours">{t('by_hours')}</option>
            <option value="half_day">{t('half_day')}</option>
            <option value="day">{t('full_day')}</option>
          </select>
        </div>
        <div className='filter'>
             {blockType !== 'hours' && (
        <>
          <button onClick={blockType === 'day' ? handleBlockDay : handleBlockHalfDay}>
            {blockType === 'day' ? t('block_full_day') : t('block_half_day')}
          </button>
          <button onClick={handleUnblockDay}>{t('unblock_day')}</button>
        </>
      )}
        </div>
      </div>
      <BigCalendarView
        appointments={events}
        onBlockHour={handleBlockHour}
        associateId={selectedAssociate}
      />
   
    </div>
  );
};

export default BlockHours;
