// src/components/Admin/AdminLogin/login.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../auth/AuthContext";
import "./login.scss";
import logo from "../../../assets/img/logo-vens.png";

const AdminLogin = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const [email, setEmail] = useState("admin@example.com");
  const [password, setPassword] = useState("password");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    debugger;

    if (!email || !password) {
      setError(t("error_fill_all_fields"));
      return;
    }

    try {
      await login(email, password);
      window.location.pathname = "/admin/index"; // Redirigir a la página de administración después del inicio de sesión
    } catch (error) {
      console.log(error);
      //setError(t("error_invalid_credentials"));
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row justify-content-center w-100">
        <div className="col-12 text-center mb-4">
          <img src={logo} alt="VENS Logo" width={300} className="logo" />
        </div>
        <div className="col-12 col-md-6">
          <div className="card">
            <div className="card-body">
              {error && <div className="alert alert-danger">{error}</div>}
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="email">{t("email")}</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("email")}
                    value={email}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password">{t("password")}</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("password")}
                    value={password}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  {t("login_button")}
                </button>
              </form>
              {/* <BiometricAuth onSuccess={handleBiometricSuccess} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
